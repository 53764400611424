import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Hero_Blog_Details from './Hero_Blog_Details'
import Blog_Details_Main from './Blog_Details_Main'
import Brand_Logo from '../../Components/Brand_Logo/Brand_Logo';
import Footer from '../../Components/Footer/Footer';

const Blog_Details = () => {
  return (
      <div>
        <Navbar></Navbar>
        <Hero_Blog_Details></Hero_Blog_Details>
        <Blog_Details_Main></Blog_Details_Main>
        <Brand_Logo></Brand_Logo>
        <Footer></Footer>
      </div>
  )
}

export default Blog_Details