import React from 'react'
import '../ContactInfo/ContactInfo.css'
import Counter1 from '../../Img/counter-1.png';
import Counter2 from '../../Img/counter-2.png';
import Counter3 from '../../Img/counter-3.png';

const ContactInfo = () => {
  return (
    <div class="section harsafe-contact-section harsafe-contact-section-02 section-padding">
        <div class="container">
            {/* <!-- Contact Wrap Start --> */}
            <div class="contact-wrap contact-wcstm">
                <div class="row">
                    <div class="col-xxl-5 col-lg-6">
                        {/* <!-- Contact Info Start --> */}
                        <div class="contact-info">
                            <div class="section-title">
                                <h2 class="title">To make requests for further information, contact us</h2>
                            </div>
                            <ul>
                                <li>
                                    {/* <!-- Contact Info Item Start --> */}
                                    <div class="contact-info-item d-flex align-items-center">
                                        <div class="contact-info-icon">
                                            <i class="flaticon-phone-call"></i>
                                        </div>
                                        <div class="contact-info-text">
                                            <h4 class="title">Contact Number</h4>
                                            <p>+91 75687 57454</p>
                                        </div>
                                    </div>
                                    {/* <!-- Contact Info Item End --> */}
                                </li>
                                <li>
                                    {/* <!-- Contact Info Item Start --> */}
                                    <div class="contact-info-item d-flex align-items-center">
                                        <div class="contact-info-icon">
                                            <i class="flaticon-email"></i>
                                        </div>
                                        <div class="contact-info-text">
                                            <h4 class="title">Our Mail</h4>
                                            <p>info@harsafe.com</p>
                                        </div>
                                    </div>
                                    {/* <!-- Contact Info Item End --> */}
                                </li>
                                <li>
                                    {/* <!-- Contact Info Item Start --> */}
                                    <div class="contact-info-item d-flex align-items-center">
                                        <div class="contact-info-icon">
                                            <i class="flaticon-pin"></i>
                                        </div>
                                        <div class="contact-info-text">
                                            <h4 class="title">Our Location</h4>
                                            <p>Reengus Sikar 332404</p>
                                        </div>
                                    </div>
                                    {/* <!-- Contact Info Item End --> */}
                                </li>
                            </ul>
                        </div>
                        {/* <!-- Contact Info End --> */}
                    </div>
                    <div class="col-xxl-7 col-lg-6">
                        {/* <!-- Contact Form Start --> */}
                        <div class="contact-form">
                            <div class="contact-form-wrap">
                                <div class="heading-wrap text-center">
                                    <span class="sub-title">Leave us massage</span>
                                    <h3 class="title">How May We Help You!</h3>
                                </div>
                                <form action="#">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            {/* <!-- Single Form Start --> */}
                                            <div class="single-form">
                                                <input type="text" placeholder="Name *"/>
                                            </div>
                                            {/* <!-- Single Form End --> */}
                                        </div>
                                        <div class="col-sm-6">
                                            {/* <!-- Single Form Start --> */}
                                            <div class="single-form">
                                                <input type="email" placeholder="Email *"/>
                                            </div>
                                            {/* <!-- Single Form End --> */}
                                        </div>
                                        <div class="col-sm-12">
                                            {/* <!-- Single Form Start --> */}
                                            <div class="single-form">
                                                <input type="text" placeholder="Subject *"/>
                                            </div>
                                            {/* <!-- Single Form End --> */}
                                        </div>
                                        <div class="col-sm-12">
                                            {/* <!-- Single Form Start --> */}
                                            <div class="single-form">
                                                <textarea placeholder="Write A Message"></textarea>
                                            </div>
                                            {/* <!-- Single Form End --> */}
                                        </div>
                                        <div class="col-sm-12">
                                            {/* <!--  Single Form Start --> */}
                                            <div class="form-btn">
                                                <button class="btn" type="submit">Send Message</button>
                                            </div>
                                            {/* <!--  Single Form End --> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* <!-- Contact Form End --> */}
                    </div>
                </div>
            </div>
            {/* <!-- Contact Wrap End --> */}
        </div>
    </div>
  )
}

export default ContactInfo