import React from 'react'
import "../SchoolMS_Hero/SchoolMS_Hero.css";
// import { Link } from "react-router-dom";

const SchoolMS_Hero = () => {
  return (
    <section className="hero_cover">
      <div className="container">
        <div className="hero_cover_content">
          <div className="row">
            <div className="col-sm-6">
              <div className="hero_cover_left">
                <div className="hero_cover_im">
                  {/* Insert image if needed */}
                </div>
                <h1 className="hero_cover_hd">
                  <span>Advanced Tools for Progressive Education</span>
                </h1>
                {/* <h2></h2> */}

                <div className="hero_cover_desc">
                  <p>Our School Management System simplifies administration, enhances communication, and provides real-time analytics, empowering educators to focus on what truly matters: teaching and learning.</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="hero_cover_right">
                <div className="hero_cover_img">
                  {/* Insert image if needed */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SchoolMS_Hero