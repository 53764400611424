import React from 'react'
import '../We_Provide/We_Provide.css'
import SerIcon1 from '../../Img/ser-icon1.svg';
import SerIcon2 from '../../Img/ser-icon2.svg';
import SerIcon3 from '../../Img/ser-icon3.svg';
import SerIcon4 from '../../Img/ser-icon4.svg';


const We_Provide = () => {
  return (
    <div class="section harsafe-service-section section-padding-02">
        <div class="container">
            {/* <!-- Service Wrap Start --> */}
            <div class="service-wrap">
                <div class="section-title text-center">
                    <h3 class="sub-title">What we provide</h3>
                    <h2 class="title">We provide truly prominent digital solutions.</h2>
                </div>
                <div class="service-content-wrap">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6">
                            {/* <!-- Service Item Start --> */}
                            <div class="service-item service-01">
                                <div class="service-img">
                                    <img src={SerIcon1} alt=""/>
                                </div>
                                <div class="service-content">
                                    <h3 class="title"><a href="service.html">Custom Software Solution</a></h3>
                                    <p>Accelerate innovation with world-class tech teams We’ll match you to an entire
                                        remote team .</p>
                                </div>
                            </div>
                            {/* <!-- Service Item End --> */}
                        </div>
                        <div class="col-xl-3 col-sm-6">
                            {/* <!-- Service Item Start --> */}
                            <div class="service-item">
                                <div class="service-img">
                                <img src={SerIcon2} alt=""/>
                                </div>
                                <div class="service-content">
                                    <h3 class="title"><a href="service.html">Business technology solution</a></h3>
                                    <p>Accelerate innovation with world-class tech teams We’ll match you to an entire
                                        remote team .</p>
                                </div>
                            </div>
                            {/* <!-- Service Item End --> */}
                        </div>
                        <div class="col-xl-3 col-sm-6">
                            {/* <!-- Service Item Start --> */}
                            <div class="service-item service-03">
                                <div class="service-img">
                                <img src={SerIcon3} alt=""/>
                                </div>
                                <div class="service-content">
                                    <h3 class="title"><a href="service.html">Recovery & IT security</a></h3>
                                    <p>Accelerate innovation with world-class tech teams We’ll match you to an entire
                                        remote team .</p>
                                </div>
                            </div>
                            {/* <!-- Service Item End --> */}
                        </div>
                        <div class="col-xl-3 col-sm-6">
                            {/* <!-- Service Item Start --> */}
                            <div class="service-item">
                                <div class="service-img">
                                <img src={SerIcon4} alt=""/>
                                </div>
                                <div class="service-content">
                                    <h3 class="title"><a href="service.html">Idea generate & solution</a></h3>
                                    <p>Accelerate innovation with world-class tech teams We’ll match you to an entire
                                        remote team .</p>
                                </div>
                            </div>
                            {/* <!-- Service Item End --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Service Wrap End --> */}
        </div>
    </div>
  )
}

export default We_Provide