import React from 'react'
import '../../Pages/ContactUs/HeroContact/HeroContact.css'
import Blog_S1 from '../../Img/blog/blog-s-1.jpg'
// import Blog_S2 from '../../Img/blog/blog-s-2.jpg'
// import Blog_S3 from '../../Img/blog/blog-s-3.jpg'
import P_Post1 from '../../Img/blog/p-post-1.jpg'
import P_Post2 from '../../Img/blog/p-post-2.jpg'
// import P_Post3 from '../../Img/blog/p-post-3.jpg'
import R_Post1 from '../../Img/blog/r-post1.jpg'
import R_Post2 from '../../Img/blog/r-post2.jpg'
import R_Post3 from '../../Img/blog/r-post3.jpg'

import { Link } from "react-router-dom";

const Blog_Details_Main = () => {
  return (
    <div class="section blog-details-section section-padding">
            <div class="container">
                {/* <!-- Blog Details Wrap Start --> */}
                <div class="blog-details-wrap">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8">
                            {/* <!-- Blog Details Post Start --> */}
                            <div class="blog-details-post">
                                {/* <!-- Single Blog Start --> */}
                                <div class="single-blog-post single-blog">
                                    <div class="blog-image">
                                        <a href="blog-details.html"><img src={Blog_S1} alt=""/></a>
                                        <div class="top-meta">
                                            <span class="date"><span>08</span>Aug</span>
                                        </div>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span class="tag"><i class="far fa-bookmark"></i> Technology / Business</span>
                                            <span><i class="fas fa-user"></i> <a href="#">Andrew Paker</a></span>
                                            <span><i class="far fa-comments"></i> 0 Comments</span>
                                        </div>
                                        <h3 class="title">How to become a successful businessman</h3>
                                        <p class="text">Accelerate innovation with world-class tech teams We’ll match you to an entire remote team of incredible freelance talent for all your software development needs. It’s been said a million times before, if not more, but technology has revolutionised our lives in ways we never expected. From the home to the office.</p>
                                    </div>
                                </div>
                                {/* <!-- Single Blog End --> */}
                                <div class="blog-details-content">
                                    {/* <!-- Blog Blockquote Start --> */}
                                    <div class="blog-quote">
                                        <blockquote class="blockquote">
                                            <p>Accelerate innovation with world-class tech teams We’ll match you to an entire remote team of incredible freelance talent for all your software development needs. there’s no corner of our day-to-day lives that hasn’t been.</p>
                                        </blockquote>
                                    </div>
                                    {/* <!-- Blog Blockquote End --> */}
                                    {/* <!-- Blog Details Text Start --> */}
                                    <div class="blog-details-text">
                                        <p>Accelerate innovation with world-class tech teams We’ll match you to an entire remote team of incredible freelance talent for all your software development needs. specializes in technological and IT-related services such as product engineering, warranty management, building cloud, infrastructure, network, etc. </p>
                                    </div>
                                    {/* <!-- Blog Details Text End --> */}
                                    {/* <!-- Blog Details Tag Share Start --> */}
                                    <div class="blog-details-tag-share">
                                        <div class="blog-details-tag">
                                            <div class="sidebar-widget">
                                                <span class="label">Tags :</span>
                                                <ul class="sidebar-tag">
                                                    <li><a href="#">LMS</a></li>
                                                    <li><a href="#">Business</a></li>
                                                    <li><a href="#">Courses</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="blog-details-share">
                                            <ul class="social-share">
                                                <li><a class="share-facebook" href="#"><i class="fab fa-facebook-f"></i></a></li>
                                                <li><a class="share-twitter" href="#"><i class="fab fa-twitter"></i></a></li>
                                                <li><a class="share-pinterest" href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <!-- Blog Details Tag Share End --> */}
                                    {/* <!-- Blog Details Post Navigation Start --> */}
                                    <div class="harsafe-post-pagination">
                                        <div class="previous-post">
                                            <div class="blog-pagination-post">
                                                <div class="post-thumb">
                                                    <a href="#">
                                                        <i class="flaticon-back"></i>
                                                        <img src={P_Post1} alt=""/>
                                                    </a>
                                                </div>
                                                <div class="post-content">
                                                    <h4 class="title"><a href="#">How Wireless Technology is Changing Business</a></h4>
                                                    <span class="date"><i class="far fa-calendar-alt"></i> May 15, 2020</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="next-post">
                                            <div class="blog-pagination-post">
                                                <div class="post-content text-end">
                                                    <h4 class="title"><a href="#">How Wireless Technology is Changing Business</a></h4>
                                                    <span class="date"><i class="far fa-calendar-alt"></i> May 15, 2020</span>
                                                </div>
                                                <div class="post-thumb">
                                                    <a href="#">
                                                    <img src={P_Post2} alt=""/>
                                                        <i class="flaticon-next"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Blog Details Post Navigation End --> */}
                                    {/* <!-- Commtent Wrap Start --> */}
                                    <div class="comment-wrap">
                                        {/* <!-- Commtent Form Start --> */}
                                        <div class="comment-form">
                                            <h3 class="comment-title">Comments (3)</h3>
                                            <p>Your email address will not be published. Required fields are marked *</p>
                                            {/* <!-- Commtent Form Wrap Start --> */}
                                            <div class="comment-form-wrap">
                                                <form action="#">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            {/* <!--  Single Form Start  --> */}
                                                            <div class="single-form">
                                                                <input class="form-control" type="text" placeholder="Your Name"/>
                                                            </div>
                                                            {/* <!--  Single Form End  --> */}
                                                        </div>
                                                        <div class="col-md-6">
                                                            {/* <!--  Single Form Start  --> */}
                                                            <div class="single-form">
                                                                <input class="form-control" type="email" placeholder="Your Email"/>
                                                            </div>
                                                            {/* <!--  Single Form End  --> */}
                                                        </div>
                                                        <div class="col-md-12">
                                                            {/* <!--  Single Form Start  --> */}
                                                            <div class="single-form">
                                                                <textarea class="form-control" placeholder="Your Message"></textarea>
                                                            </div>
                                                            {/* <!--  Single Form End  --> */}
                                                        </div>
                                                        <div class="col-md-12">
                                                            {/* <!--  Single Form Start  --> */}
                                                            <div class="form-btn">
                                                                <button class="btn" type="submit">Submit</button>
                                                            </div>
                                                            {/* <!--  Single Form End  --> */}
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            {/* <!-- Commtent Form Wrap End --> */}
                                        </div>
                                        {/* <!-- Commtent Form End --> */}
                                    </div>
                                    {/* <!-- Commtent Wrap End --> */}
                                </div>
                            </div>
                            {/* <!-- Blog Details Post End --> */}
                        </div>
                        <div class="col-xl-3 col-lg-4">
                            {/* <!-- Blog Sidebar Start --> */}
                            <div class="blog-sidebar">
                                {/* <!-- Sidebar Widget Start --> */}
                                <div class="sidebar-widget sidebar-widget-1">
                                    {/* <!-- Widget Search Form Start --> */}
                                    <form class="search-form" action="#">
                                        <input type="text" placeholder="Write your keyword..."/>
                                        <button type="submit"><i class="fas fa-search"></i></button>
                                    </form>
                                    {/* <!-- Widget Search Form End --> */}
                                </div>
                                {/* <!-- Sidebar Widget End --> */}

                                {/* <!-- Sidebar Widget Start --> */}
                                <div class="sidebar-widget">
                                    {/* <!-- Widget Title Start --> */}
                                    <div class="widget-title">
                                        <h3 class="title">Popular Posts</h3>
                                    </div>
                                    {/* <!-- Widget Title End --> */}
                                    {/* <!-- Widget Recent Post Start --> */}
                                    <div class="recent-posts">
                                        <ul>
                                            <li>
                                                <a class="post-link" href="blog-details.html">
                                                    <div class="post-thumb">
                                                        <img src={R_Post1} alt=""/>
                                                    </div>
                                                    <div class="post-text">
                                                        <h4 class="title">How Wireless Technology is Changing Business</h4>
                                                        <span class="post-meta"><i class="far fa-calendar-alt"></i> May 15, 2020</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="post-link" href="blog-details.html">
                                                    <div class="post-thumb">
                                                    <img src={R_Post2} alt=""/>
                                                    </div>
                                                    <div class="post-text">
                                                        <h4 class="title">How Wireless Technology is Changing Business</h4>
                                                        <span class="post-meta"><i class="far fa-calendar-alt"></i> May 15, 2020</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a class="post-link" href="blog-details.html">
                                                    <div class="post-thumb">
                                                    <img src={R_Post3} alt=""/>
                                                    </div>
                                                    <div class="post-text">
                                                        <h4 class="title">How Wireless Technology is Changing Business</h4>
                                                        <span class="post-meta"><i class="far fa-calendar-alt"></i> May 15, 2020</span>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <!-- Widget Recent Post End --> */}
                                </div>
                                {/* <!-- Sidebar Widget End --> */}

                                <div class="sidebar-widget">
                                    <div class="widget-banner">
                                        <div class="banner-content">
                                            <h4 class="title">The leading platform</h4>
                                            <a class="btn" href="contact.html">Get Started</a>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Sidebar Widget Start --> */}
                                <div class="sidebar-widget">
                                    {/* <!-- Widget Title Start --> */}
                                    <div class="widget-title">
                                        <h3 class="title">Categories</h3>
                                    </div>
                                    {/* <!-- Widget Title End --> */}
                                    {/* <!-- Widget Category Start --> */}
                                    <ul class="category">
                                        <li class="cate-item"><a href="blog.html"><i class="flaticon-next"></i> Technology <span class="post-count">3</span></a></li>
                                        <li class="cate-item"><a href="blog.html"><i class="flaticon-next"></i> Innovation <span class="post-count">5</span></a></li>
                                        <li class="cate-item"><a href="blog.html"><i class="flaticon-next"></i> Learning <span class="post-count">3</span></a></li>
                                        <li class="cate-item"><a href="blog.html"><i class="flaticon-next"></i> Information <span class="post-count">3</span></a></li>
                                    </ul>
                                    {/* <!-- Widget Category End --> */}
                                </div>
                                {/* <!-- Sidebar Widget End --> */}

                                {/* <!-- Sidebar Widget Start --> */}
                                <div class="sidebar-widget">
                                    {/* <!-- Widget Title Start --> */}
                                    <div class="widget-title">
                                        <h3 class="title">Tags</h3>
                                    </div>
                                    {/* <!-- Widget Title End --> */}
                                    {/* <!-- Widget Category Start --> */}
                                    <ul class="sidebar-tag">
                                        <li><a href="#">Learning</a></li>
                                        <li><a href="#">Course</a></li>
                                        <li><a href="#">Business</a></li>
                                        <li><a href="#">Udemy</a></li>
                                        <li><a href="#">Online</a></li>
                                        <li><a href="#">Technology</a></li>
                                    </ul>
                                    {/* <!-- Widget Category End --> */}
                                </div>
                                {/* <!-- Sidebar Widget End --> */}
                            </div>
                            {/* <!-- Blog Sidebar End --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- Blog Details Wrap End --> */}
            </div>
        </div>
  )
}

export default Blog_Details_Main