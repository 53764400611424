import React, { useState, useEffect } from "react";
import "../Navbar/Navbar.css";
import "../../App.css";
import LogoBlack from "../../Img/logo-2.png";
import LogoWhite from "../../Img/logo.png";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [navbar, setNavbar] = useState(false);

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 180) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };

    window.addEventListener('scroll', changeBackground);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, []);

  return (
    <nav>
      <div className={navbar ? 'section fixed-to header-section header-section-02 sticky' : 'section fixed-to header-section header-section-02'}>
        <div className="container">
          <div className="header-wrap">
            <div className="header-logo">
              <NavLink to="/" className="logo-black">
                <img src={LogoBlack} alt="Logo Black" />
              </NavLink>
              <NavLink to="/" className="logo-white">
                <img src={LogoWhite} alt="Logo White" />
              </NavLink>
            </div>

            <div className="header-menu d-none d-lg-block">
              <ul className="main-menu">
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/Service">Service</NavLink>
                </li>
                <li>
                  <NavLink to="/AboutUs">About Us</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/Blog">Blog</NavLink>
                </li> */}
                <li>
                  <NavLink to="/ContactUs">Contact Us</NavLink>
                </li>
                <li>
                  <NavLink to="/OurTeam">Our Team</NavLink>
                </li>
              </ul>
            </div>

            <div className="header-meta">
              <div className="header-toggle d-lg-none">
                <button
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample"
                  onClick={() => setShowMediaIcons(!showMediaIcons)}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={showMediaIcons ? "offcanvas offcanvas-end show fade" : "offcanvas offcanvas-end"} id="offcanvasExample">
        <div className="offcanvas-header">
          <div className="offcanvas-logo">
            <NavLink to="/">
              <img src={LogoWhite} alt="Logo White" />
            </NavLink>
          </div>
          <button
            type="button"
            className="close-btn"
            data-bs-dismiss="offcanvas"
            onClick={() => setShowMediaIcons(!showMediaIcons)}
          >
            <i className="flaticon-close">X</i>
          </button>
        </div>

        <div className="offcanvas-body">
          <div className="offcanvas-menu">
            <ul className="main-menu">
              <li>
                <NavLink to="/" onClick={() => setShowMediaIcons(false)}>Home</NavLink>
              </li>
              <li>
                <NavLink to="/AboutUs" onClick={() => setShowMediaIcons(false)}>About Us</NavLink>
              </li>
              <li>
                <NavLink to="/Service" onClick={() => setShowMediaIcons(false)}>Service</NavLink>
              </li>
              {/* <li>
                <NavLink to="/Blog" onClick={() => setShowMediaIcons(false)}>Blog</NavLink>
              </li> */}
              <li>
                <NavLink to="/ContactUs" onClick={() => setShowMediaIcons(false)}>Contact</NavLink>
              </li>
              <li>
                <NavLink to="/OurTeam" onClick={() => setShowMediaIcons(false)}>Our Team</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
