import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import ServiceDetailsHero from "./ServiceDetailsHero";
import Feature from "./Feature/Feature";
import ServiceSection from "./ServiceSection/ServiceSection";
import SSSlider from "./SSSlider/SSSlider";
import Skill from "./Skill/Skill";
import Testimonial from "./Testimonial/Testimonial";
import Counter from "./Counter/Counter";
import Pricing from "./Pricing/Pricing";
import Faq from "./Faq/Faq";
import Footer from "../../Components/Footer/Footer";

const ServiceDetails = () => {
  return (
    <div>
      <Navbar></Navbar>
      <ServiceDetailsHero></ServiceDetailsHero>
      <ServiceSection></ServiceSection>
      <Feature></Feature>
      <Skill></Skill>
      {/* <SSSlider></SSSlider> */}
      <Testimonial></Testimonial>
      {/* <Counter></Counter> */}
      <Faq></Faq>
      <Pricing></Pricing>
      <Footer></Footer>
    </div>
  );
};

export default ServiceDetails;
