import React from 'react'
import '../../Components/PartnerSIT/PartnerSIT.css'
import SoumyaLogo from '../../Img/soumyalogo.png'

const PartnerSIT = () => {
  return (
    <section className="partnerPayments_outer">
            <div className="container">
                <div className="partnerPayments_outer_inner">
                    <div className="partnerPayments_outer_inner_Left">
                        <h2>
                            <p>Our Trusted</p>
                            <span className="partnerPayment_Large">IT Partner</span>
                            <b>For Seamless </b> 
                            Success
                        </h2>
                        <span>Empowering businesses with innovative solutions, reliable support, and proactive guidance for seamless success.</span>
                    </div>
                    <div className="partnerPayments_outer_inner_right">
                        <div className="slogen_Title">
                            <span>Click for Contact</span>
                        </div>
                        <ul>
                            <li>
                                <span>
                                    <a href="https://soumyaitsolution.com/" target='_blank'>
                                        <img src={SoumyaLogo} alt="PhonePe" />
                                    </a>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default PartnerSIT