import React from 'react'
import '../Brand_Logo/Brand_Logo.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y } from 'swiper/modules';
import {EffectFade} from 'swiper/modules'
import 'swiper/css';
import 'swiper/swiper-bundle.css'

import Google from '../../Img/brand/google.png';
import Linkedin from '../../Img/brand/linked.png';
import Facebook from '../../Img/brand/fb.png';
import Instagram from '../../Img/brand/instagram.png';
import TwitterX from '../../Img/brand/x.png';


const Brand_Logo = () => {
  return (
    <div class="section harsafe-brand-section harsafe-brand-section-02">
        <div class="container">
            <div class="brand-wrapper text-center brand-active-about-react">

            <Swiper
            // modules={[EffectFade, A11y]}
            spaceBetween={10}
            slidesPerView={5}
            effect="fade"
            loop={true}
            autoplay={true}
            speed={900}
          //   navigation
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}


      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        400:{
          slidesPerView:1,
        },
        639: {
          slidesPerView: 2,
        },
        865:{
          slidesPerView:3
        },
        1000:{
          slidesPerView:5
        },
        1500:{
          slidesPerView:5
        },
        1700:{
          slidesPerView:5
        }
      }}
    //   onSlideChange={() => console.log('slide change')}
    //   onSwiper={(swiper) => console.log(swiper)}
    >
      
      <SwiperSlide><div class="swiper-slide single-brand">
                                <img src={Google} alt="Brand"/>
                            </div></SwiperSlide>
      <SwiperSlide><div class="swiper-slide single-brand">
                                <img src={Facebook} alt="Brand"/>
                            </div></SwiperSlide>
      <SwiperSlide><div class="swiper-slide single-brand">
                                <img src={Instagram} alt="Brand"/>
                            </div></SwiperSlide>
      <SwiperSlide><div class="swiper-slide single-brand">
                                <img src={Google} alt="Brand"/>
                            </div></SwiperSlide>
      <SwiperSlide><div class="swiper-slide single-brand">
                                <img src={Linkedin} alt="Brand"/>
                            </div></SwiperSlide>
      <SwiperSlide><div class="swiper-slide single-brand">
                                <img src={TwitterX} alt="Brand"/>
                            </div></SwiperSlide>
      <SwiperSlide><div class="swiper-slide single-brand">
                                <img src={Google} alt="Brand"/>
                            </div></SwiperSlide>
      <SwiperSlide><div class="swiper-slide single-brand">
                                <img src={Facebook} alt="Brand"/>
                            </div></SwiperSlide>
      <SwiperSlide><div class="swiper-slide single-brand">
                                <img src={Instagram} alt="Brand"/>
                            </div></SwiperSlide>
      <SwiperSlide><div class="swiper-slide single-brand">
                                <img src={Google} alt="Brand"/>
                            </div></SwiperSlide>
      <SwiperSlide><div class="swiper-slide single-brand">
                                <img src={Linkedin} alt="Brand"/>
                            </div></SwiperSlide>
      <SwiperSlide><div class="swiper-slide single-brand">
                                <img src={TwitterX} alt="Brand"/>
                            </div></SwiperSlide>

    </Swiper>
                {/* <div class="brand-active">
                    <div class="swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide single-brand">
                                <img src={Google} alt="Brand"/>
                            </div>
                            <div class="swiper-slide single-brand">
                                <img src={Facebook} alt="Brand"/>
                            </div>
                            <div class="swiper-slide single-brand">
                                <img src={Instagram} alt="Brand"/>
                            </div>
                            <div class="swiper-slide single-brand">
                                <img src={Google} alt="Brand"/>
                            </div>
                            <div class="swiper-slide single-brand">
                                <img src={Linkedin} alt="Brand"/>
                            </div>
                            <div class="swiper-slide single-brand">
                                <img src={TwitterX} alt="Brand"/>
                            </div>
                            <div class="swiper-slide single-brand">
                                <img src={TwitterX} alt="Brand"/>
                            </div>
                            <div class="swiper-slide single-brand">
                                <img src={TwitterX} alt="Brand"/>
                            </div>
                            <div class="swiper-slide single-brand">
                                <img src={TwitterX} alt="Brand"/>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
    
  )
}

export default Brand_Logo