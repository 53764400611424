import React from 'react'
import '../Footer/Footer.css'
import LogoBlack from '../../Img/logo-2.png';


const Footer = () => {
  return (
    <div class="section footer-section footer-section-04">

        <div class="container">
            {/* <!-- Footer Widget Wrap Start --> */}
            <div class="footer-widget-wrap">
                <div class="row">
                    <div class="col-lg-3 col-sm-6">
                        {/* <!-- Footer Widget Start --> */}
                        <div class="footer-widget-about">
                            <a class="footer-logo" href="index.html">
                                <img src={LogoBlack} alt="Logo"/>
                                </a>
                            <p>Accelerate innovation with world-class tech teams We’ll match you to an entire remote
                                team of incredible freelance talent.</p>
                            <div class="footer-social">
                                <ul class="social">
                                    <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- Footer Widget End --> */}
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        {/* <!-- Footer Widget Start --> */}
                        <div class="footer-widget">
                            <h4 class="footer-widget-title">Useful Links</h4>

                            <div class="widget-link">
                                <ul class="link">
                                    <li><a href="#">Terms & Conditions</a></li>
                                    <li><a href="#">About Company</a></li>
                                    <li><a href="#">Payment Gatway</a></li>
                                    <li><a href="#">Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- Footer Widget End --> */}
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        {/* <!-- Footer Widget Start --> */}
                        <div class="footer-widget">
                            <h4 class="footer-widget-title">Our Services</h4>

                            <div class="widget-link">
                                <ul class="link">
                                    <li><a href="#">Data Security</a></li>
                                    <li><a href="#">IT Managment</a></li>
                                    <li><a href="#">Outsourcing</a></li>
                                    <li><a href="#">Networking</a></li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- Footer Widget End --> */}
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        {/* <!-- Footer Widget Start --> */}
                        <div class="footer-widget">
                            <h4 class="footer-widget-title">Contact Information</h4>

                            <div class="widget-info">
                                <ul>
                                    <li>
                                        <div class="info-icon">
                                            <i class="flaticon-phone-call"></i>
                                        </div>
                                        <div class="info-text">
                                            <span><a href="#">+91 75687 76120</a></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="info-icon">
                                            <i class="far fa-envelope-open"></i>
                                        </div>
                                        <div class="info-text">
                                            <span><a href="#">info@harsafe.com</a></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="info-icon">
                                            <i class="flaticon-pin"></i>
                                        </div>
                                        <div class="info-text">
                                            <span>Reengus Sikar Rajasthan, 332404</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- Footer Widget End --> */}
                    </div>
                </div>
            </div>
            {/* <!-- Footer Widget Wrap End --> */}
        </div>

        {/* <!-- Footer Copyright Start --> */}
        <div class="footer-copyright-area">
            <div class="container">
                <div class="footer-copyright-wrap">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            {/* <!-- Footer Copyright Text Start --> */}
                            <div class="copyright-text text-center">
                                <p>© Copyrights 2024 harsafe All rights reserved. </p>
                            </div>
                            {/* <!-- Footer Copyright Text End --> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Footer Copyright End --> */}
    </div>
  )
}

export default Footer