import React from 'react';
import '../Hero/Hero.css';
// import Bg1 from '../../Img/side-view-woman-working-media-field-with-computer-headphones.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import {EffectFade} from 'swiper/modules'
import 'swiper/css';
import 'swiper/swiper-bundle.css'



// var bgStyle = {
    
//     background: linear-gradient(325deg, rgba(0, 9, 14, 0) 0%, rgba(0, 9, 18, 1) 100%), url({Bg1}),
//   };


const Hero = () => {
  return (
    <section class="homeSlider homeSliderCar">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="sliderBody">
                        <div class="slider">
                            <div class="swiper homeMainSlider">
                                <div class="swiper-wrapper">
                                <Swiper
      modules={[Pagination, EffectFade, A11y]}
      spaceBetween={0}
      slidesPerView={1}
      effect="fade"
      loop={true}
      autoplay={true}
      speed={900}
    //   navigation
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
      <SwiperSlide>
                                        <div class="sliderItem">
                                            <div class="row align-items-center">

                                                <div class="col-12 col-lg-6">
                                                    <span class="subHeading">We can managed all digital services</span>

                                                    <h2>Your partner for digital solutions</h2>

                                                    <p>We provide the most responsive and functional IT design for
                                                        companies and businesses worldwide.</p>

                                                    <a href="#" class="default-btn">DISCOVER NOW</a>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    {/* <img className="hrimg" src={} alt="Produsctsss" />  */}
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
      <SwiperSlide>
                                        <div class="sliderItem">
                                            <div class="row align-items-center">
                                                <div class="col-12 col-lg-6">
                                                    <span class="subHeading">We can managed all digital services</span>
                                                    <h2>Your partner for digital solutions</h2>
                                                    <p>We provide the most responsive and functional IT design for
                                                        companies and businesses worldwide.</p>
                                                    <a href="#" class="default-btn">DISCOVER NOW</a>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                      <img className="hrimg" src="https://carzone.dexignlab.com/xhtml/images/car2.png"
                                                    alt="Product" />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
      <SwiperSlide>
                                        <div class="sliderItem">
                                            <div class="row align-items-center">
                                                <div class="col-12 col-lg-6">
                                                    <span class="subHeading">We can managed all digital services</span>

                                                    <h2>Your partner for digital solutions</h2>

                                                    <p>We provide the most responsive and functional IT design for
                                                        companies and businesses worldwide.</p>

                                                    <a href="#" class="default-btn">DISCOVER NOW</a>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                     <img className="hrimg" src="https://carzone.dexignlab.com/xhtml/images/car3.png" alt="Product" /> 
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
      {/* <SwiperSlide>Slide 4</SwiperSlide> */}
    </Swiper>
                                    
                                    
                                    

                                </div>

                                <div class="swiper-pagination paginationFix"></div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </section>
  )
}

export default Hero