import React, { useState } from "react";
import "../Faq/Faq.css";

const AccordionItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className="accordion-item">
      <div className="accordion-header" id={question}>
        <button
          className={`accordion-button ${isOpen ? "" : "collapsed"}`}
          type="button"
          onClick={onClick}
          aria-expanded={isOpen}
          aria-controls={question}
        >
          <span className="title">{question}</span>
        </button>
      </div>
      <div
        id={question}
        className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
        aria-labelledby={question}
      >
        <div className="accordion-body">{answer}</div>
      </div>
    </div>
  );
};

const Accordion = ({}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const items = [
    {
      question: "How long does it take to finish projects?",
      answer:
        "Accelerate innovation with world-class tech teams. We’ll match you to an entire remote team of incredible freelance talent for all your software needs.",
    },
    {
      question: "Support & Policy rules?",
      answer:
        "We can help you channel your potential by implementing your idea. We take care of all your needs, crafting specific and targeted solutions.",
    },
    {
      question: "Can we get a refund?",
      answer:
        "Accelerate innovation with world-class tech teams. We’ll match you to an entire remote team of incredible freelance talent for all your software needs.",
    },
    {
      question: "Can we get a refund?",
      answer:
        "Accelerate innovation with world-class tech teams. We’ll match you to an entire remote team of incredible freelance talent for all your software needs.",
    },
  ];

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="section harsafe-choose-us-section-02 section-padding">
      <div className="container">
        {/* <!-- Pricing Wrap Start --> */}
        <div className="pricing-wrap">
          <div className="section-title text-center">
            <h3 className="sub-title">FAQ</h3>
            <h2 className="title">FAQ's</h2>
          </div>
          <div className="pricing-content-wrap">
            <div className="col-lg-8 col-md-12 m-auto">

              <div className="choose-us-right">
                <div className="faq-accordion">
                  <div className="accordion" id="accordionExample">
                    {items.map((item, index) => (
                      <AccordionItem
                        key={index}
                        question={item.question}
                        answer={item.answer}
                        isOpen={activeIndex === index}
                        onClick={() => handleClick(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              

            </div>
          </div>
        </div>
        {/* <!-- Pricing Wrap End --> */}
      </div>
    </div>
  );
};

export default Accordion;
