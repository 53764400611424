import React from "react";
import "../ServiceDetails/ServiceDetailsHero.css";
// import { Link } from "react-router-dom";

const ServiceDetailsHero = () => {
  return (
    <section className="ready_game_cover">
      <div className="container">
        <div className="game_cover_content">
          <div className="row">
            <div className="col-sm-6">
              <div className="game_cover_left">
                <div className="game_cover_im">
                  {/* Insert image if needed */}
                </div>
                <h1 className="game_cover_hd">
                  <span className="btxt">DS</span><span className="gtxt">hield </span><span>Pro</span>
                </h1>
                {/* <h2></h2> */}

                <div className="game_cover_desc">
                  <p>
                    DShield Pro App offers robust security with real-time threat
                    detection, seamless integration, and user-friendly interface
                    for comprehensive device protection.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="game_cover_right">
                <div className="game_cover_img">
                  {/* Insert image if needed */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceDetailsHero;
