import React from "react";
import {useState} from 'react'
import "../FeaturesSpecial/FeaturesSpecial.css";

const FeaturesSpecial = () => {

    const [toggle, setToggle] = useState(1)
    function updateToggle(id) {
        setToggle(id)
    }

  return (
    <div className="section harsafe-service-section-07 section-padding">
      <div className="container">
        {/* <!-- Service Wrap Start --> */}
        <div className="service-wrap">
          <div className="section-title text-center">
            <h3 className="sub-title color-3">Special Features</h3>
            <h2 className="title">What make it Smart School Management System</h2>
          </div>
          <div className="service-content-wrap">

          <div class="row">
                <div class="col-md-4 mb-3">
                    <div class="tab-toggle-btn">
                        <a href="#"><i class="fas fa-bars">aa</i></a>
                    </div>
                    <ul class="nav nav-pills flex-column service-tab" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true" onClick={() => updateToggle(1)} className={toggle === 1 ? "nav-link active" : "nav-link"}>Academic</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false" onClick={() => updateToggle(2)} className={toggle === 2 ? "nav-link active" : "nav-link"}>Pages &amp; Shortcodes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="contact-tab" data-toggle="tab" role="tab" aria-controls="contact" aria-selected="false" onClick={() => updateToggle(3)} className={toggle === 3 ? "nav-link active" : "nav-link"}>Administrator</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="acc-tab" data-toggle="tab" role="tab" aria-controls="acc" aria-selected="false" onClick={() => updateToggle(4)} className={toggle === 4 ? "nav-link active" : "nav-link"}>Accounting</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="exam-tab" data-toggle="tab" role="tab" aria-controls="exam" aria-selected="false" onClick={() => updateToggle(5)} className={toggle === 5 ? "nav-link active" : "nav-link"}>Examination</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="stubent-tab" data-toggle="tab" role="tab" aria-controls="student" aria-selected="false" onClick={() => updateToggle(6)} className={toggle === 6 ? "nav-link active" : "nav-link"}>Student</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pay-tab" data-toggle="tab" role="tab" aria-controls="pay" aria-selected="false" onClick={() => updateToggle(7)} className={toggle === 7 ? "nav-link active" : "nav-link"}>Payment Gateways</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="sms-tab" data-toggle="tab" role="tab" aria-controls="sms" aria-selected="false" onClick={() => updateToggle(8)} className={toggle === 8 ? "nav-link active" : "nav-link"}>SMS Providers</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="seting-tab" data-toggle="tab" role="tab" aria-controls="seting" aria-selected="false" onClick={() => updateToggle(9)} className={toggle === 9 ? "nav-link active" : "nav-link"}>Settings</a>
                        </li>
                    </ul>
                </div>

                {/* <!-- /.col-md-4 --> */}
                <div class="col-md-8">
                    <div class="tab-content" id="myTabContent">
                        <div class={toggle === 1 ? "tab-pane fade show active" : "tab-pane fade"} id="home" role="tabpanel">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f1.png" alt="Classes and Sections"/>
                                        </div>
                                        <div class="content">
                                            <h3>Classes and Sections</h3>
                                            <p>Create classes, section in a school</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f2.png" alt="Subject"/>
                                        </div>
                                        <div class="content">
                                            <h3>Subject</h3>
                                            <p>Add different types of subjects</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f3.png" alt="Study Materials"/>
                                        </div>
                                        <div class="content">
                                            <h3>Study Materials</h3>
                                            <p>Add and distribute study materials to classes</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f4.png" alt="school management Attendance"/>
                                        </div>
                                        <div class="content">
                                            <h3>Attendance</h3>
                                            <p>Take date-wise attendance of students</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f5.png" alt="Class Timetable"/>
                                        </div>
                                        <div class="content">
                                            <h3>Class Timetable</h3>
                                            <p>Create and display timetable for classes</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f5.png" alt="Noticeboard"/>
                                        </div>
                                        <div class="content">
                                            <h3>Noticeboard</h3>
                                            <p>Display recent notices using widget</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f9.png" alt="Noticeboard"/>
                                        </div>
                                        <div class="content">
                                            <h3>Online classes</h3>
                                            <p> Create unlimited live classes. Teacher can manage the classes and student can learn Online with mobiles and any other devices.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={toggle === 2 ? "tab-pane fade show active" : "tab-pane fade"} id="profile" role="tabpanel">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f1.png" alt="Fee Collection Form"/>
                                        </div>
                                        <div class="content">
                                            <h3>Fee Collection Form</h3>
                                            <p>Receive online payment using
                                                payment gateway</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f2.png" alt="Inquiry Form"/>
                                        </div>
                                        <div class="content">
                                            <h3>Inquiry Form</h3>
                                            <p>Display admission inquiry form on a page</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f3.png" alt="Student Login Form"/>
                                        </div>
                                        <div class="content">
                                            <h3>Student Login Form</h3>
                                            <p>Display student login form on a page</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f4.png" alt="Student Dashboard"/>
                                        </div>
                                        <div class="content">
                                            <h3>Student Dashboard</h3>
                                            <p>Separate dashboard for students</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f5.png" alt="Student Management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Student Management</h3>
                                            <p>Manage student records sessions wise</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f5.png" alt="Exam Time Table"/>
                                        </div>
                                        <div class="content">
                                            <h3>Exam Time Table</h3>
                                            <p>Create exams and publish exam time table</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={toggle === 3 ? "tab-pane fade show active" : "tab-pane fade"} id="contact" role="tabpanel">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f6.png" alt="Roles and Permission"/>
                                        </div>
                                        <div class="content">
                                            <h3>Roles and Permission</h3>
                                            <p>Create custom roles, assign permissions</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f7.png" alt="Admin"/>
                                        </div>
                                        <div class="content">
                                            <h3>Admin</h3>
                                            <p>Assign admins to manage the school</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f8.png" alt="Staff"/>
                                        </div>
                                        <div class="content">
                                            <h3>Staff</h3>
                                            <p>Add school staff with permissions</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f9.png" alt="Teacher"/>
                                        </div>
                                        <div class="content">
                                            <h3>Teacher</h3>
                                            <p>Add school teacher to manage students</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f10.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Accountant</h3>
                                            <p>Manage fees, expense and income</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={toggle === 4 ? "tab-pane fade show active" : "tab-pane fade"} id="acc" role="tabpanel">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p1.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Fee Invoice Generation</h3>
                                            <p>Generate fee invoices in bulk</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p2.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Expense Management</h3>
                                            <p>Add expense categories &amp; date-wise expense</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p3.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Income Management</h3>
                                            <p>Add income categories &amp; date-wise income</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p4.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Payment Collection</h3>
                                            <p>Collect payment offline and online</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={toggle === 5 ? "tab-pane fade show active" : "tab-pane fade"} id="seting" role="tabpanel">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f11.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>School Settings</h3>
                                            <p>School logo, name, address, phone, email</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f12.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Email Carrier</h3>
                                            <p>SMTP, WP Mail</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f13.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>SMS Carrier</h3>
                                            <p>Twilio, Nexmo, MsgClub, SMS Striker, Point SMS, Msg91, TextLocal, Ebulksms, Pob Talk, Vinuthan,India Text, Kivalosolutions SMS, Getewaysms, Bulksmsgateway, SendPk </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f14.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>SMS &amp; Email Templates</h3>
                                            <p>Student Admission, Invoice Generation, Online-Offline Fee Submission</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={toggle === 6 ? "tab-pane fade show active" : "tab-pane fade"} id="exam" role="tabpanel">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f15.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Manage Exams</h3>
                                            <p>Add exams, exam papers, timings, date and center</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f16.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Exam Time Table</h3>
                                            <p>Generate and publish exam time table</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f17.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Publish Admit Cards</h3>
                                            <p>Generate and publish exam admit cards</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f14.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Exam Results</h3>
                                            <p>Add and publish exam results</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={toggle === 7 ? "tab-pane fade show active" : "tab-pane fade"} id="student" role="tabpanel">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p5.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Student Admission</h3>
                                            <p>Add new admissions to a class</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p6.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Student Promotion</h3>
                                            <p>Promote student from one class to another</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p7.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Student Transfer</h3>
                                            <p>Transfer student from one school to another</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={toggle === 8 ? "tab-pane fade show active" : "tab-pane fade"} id="pay" role="tabpanel">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p5.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Paypal</h3>
                                            <p>PayPal is the safer way to make an online payment</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p6.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Stripe</h3>
                                            <p>Online payment processing for internet businesses</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p7.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Razorpay</h3>
                                            <p>Accept payments instantly with Razorpay's free payment gateway.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p4.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>PayTM</h3>
                                            <p>Mobile Money, Visa, Master-card and other payment UPI</p>
                                        </div>
                                    </div>
                                </div>
								<div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p4.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Paystack </h3>
                                            <p>Mobile Money, Visa, Mastercard and American Express</p>
                                        </div>
                                    </div>
                                </div>
								<div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p4.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Pesapal</h3>
                                            <p>Mobile Money, Visa, Mastercard and American Express</p>
                                        </div>
                                    </div>
                                </div>
								<div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p4.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>SSL Commerz</h3>
                                            <p>Mobile Money, Visa, Mastercard and American Express</p>
                                        </div>
                                    </div>
                                </div>
								<div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/p4.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>foree( comming soon )</h3>
                                            <p>Mobile Money, Visa, Mastercard and American Express</p>
                                        </div>
                                    </div>
                                </div>
								
                            </div>
                        </div>
                        <div class={toggle === 9 ? "tab-pane fade show active" : "tab-pane fade"} id="sms" role="tabpanel">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f2.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Twilio</h3>
                                            <p>International SMS provider with scalable, powerful API</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f3.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Nexmo</h3>
                                            <p>International SMS provider with APIs for SMS</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f4.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>MsgClub</h3>
                                            <p>Bulk SMS Gateway Provider in India</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f5.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>SMS Striker</h3>
                                            <p>Bulk SMS Service Provider in India</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f6.png" alt="school  management"/>
                                        </div>
                                        <div class="content">
                                            <h3>Msg91</h3>
                                            <p>Enterprise SMS Solution providing Bulk SMS</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="coman-feature">
                                        <div class="icon">
                                            <img src="https://theschool-management.com/wp-content/themes/theschool-2020/assets/images/f7.png" alt="Point SMS"/>
                                        </div>
                                        <div class="content">
                                            <h3>Point SMS</h3>
                                            <p>PointSMS is an easy to use software for sending SMS</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /.col-md-8 --> */}
            </div>

          </div>
        </div>
        {/* <!-- Service Wrap End --> */}
      </div>
    </div>
  );
};

export default FeaturesSpecial;
