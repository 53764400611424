import React from "react";
import "../ServiceSection/ServiceSection.css";

// import Lock_Unlock from "./src/img/dshield";
import Lock_Unlock from "../../../Img/dshield/lock.svg"
import Unlock_Code from "../../../Img/dshield/Unlock_Code.svg"
import Thief_Alarm from "../../../Img/dshield/Thief_Alarm.svg"
import EMI_Reminder from "../../../Img/dshield/EMI_Reminder.svg"
import Get_Location from "../../../Img/dshield/Get_Location.svg"
import Get_Number from "../../../Img/dshield/Get_Number.svg"
import Screen_Password_Change from "../../../Img/dshield/Screen_Password_Change.svg"
import Device_Restart from "../../../Img/dshield/Device_Restart.svg"
import Volume_Enable from "../../../Img/dshield/Volume_Enable.svg"
import Call_Enable from "../../../Img/dshield/Call_Enable.svg"
import OTG_Enable from "../../../Img/dshield/OTG_Enable.svg"
import Ads_On from "../../../Img/dshield/Ads_On.svg"
import Allow_Reset_Permission from "../../../Img/dshield/Allow_Reset_Permission.svg"
import Device_Hard_Reset from "../../../Img/dshield/Device_Hard_Reset.svg"


const services = [
  {
    imgSrc: Lock_Unlock,
    imgAlt: "Lock / Unlock",
    title: "Lock / Unlock",
    description: "Easily lock or unlock your device with a single tap, ensuring quick access while maintaining security. Perfect for both everyday use and emergency situations."
  },
  {
    imgSrc: Unlock_Code,
    imgAlt: "Unlock Code",
    title: "Unlock Code",
    description: "The unlock code grants access to secure features, ensuring only authorized users can utilize the application’s advanced functionalities, enhancing security and personalized user experience."
  },
  {
    imgSrc: Thief_Alarm,
    imgAlt: "Thief Alarm",
    title: "Thief Alarm",
    description: "The Thief Alarm system offers real-time alerts, motion detection, and remote monitoring, ensuring enhanced security and immediate response to unauthorized access or suspicious activities."
  },
  {
    imgSrc: EMI_Reminder,
    imgAlt: "EMI Reminder",
    title: "EMI Reminder",
    description: "Stay on top of your finances with timely EMI reminders. Never miss a payment deadline, ensuring your credit score remains healthy and stress-free financial management."
  },
  {
    imgSrc: Get_Location,
    imgAlt: "Get Location",
    title: "Get Location",
    description: "Our app provides real-time location tracking, ensuring you always know where you are."
  },
  {
    imgSrc: Get_Number,
    imgAlt: "Get Number",
    title: "Get Number",
    description: "Retrieve mobile numbers from devices effortlessly, ensuring seamless integration and user convenience for applications requiring contact information access."
  },
  {
    imgSrc: Screen_Password_Change,
    imgAlt: "Screen Password Change",
    title: "Screen Password Change",
    description: "Easily update passwords on-screen through our app for seamless security management and peace of mind, ensuring your accounts remain protected at all times."
  },
  {
    imgSrc: Device_Restart,
    imgAlt: "Device Restart",
    title: "Device Restart",
    description: "Effortlessly restart your device remotely using our intuitive app interface, ensuring seamless control and convenience from anywhere with just a few taps."
  },
  {
    imgSrc: Volume_Enable,
    imgAlt: "Volume Enable / Disable",
    title: "Volume Enable / Disable",
    description: "Control audio output remotely with our app, allowing seamless volume adjustment anytime, anywhere for your convenience and enjoyment."
  },
  {
    imgSrc: Call_Enable,
    imgAlt: "Call Enable / Disable",
    title: "Call Enable / Disable",
    description: "Control device functions remotely with the app, enabling or disabling features effortlessly from anywhere with just a few taps."
  },
  {
    imgSrc: OTG_Enable,
    imgAlt: "OTG Enable / Disable",
    title: "OTG Enable / Disable",
    description: "Enable or disable OTG functionality conveniently through our app, ensuring seamless connectivity and control over external devices with ease."
  },
  {
    imgSrc: Ads_On,
    imgAlt: "Ads On / Off",
    title: "Ads On / Off",
    description: "Control advertisements directly within the app with a simple toggle switch, providing users the flexibility to enable or disable ads based on preference."
  },
  {
    imgSrc: Lock_Unlock,
    imgAlt: "Offline Lock / Unlock",
    title: "Offline Lock / Unlock",
    description: "Enable remote locking and unlocking of devices offline using the app, ensuring secure access control even without an internet connection."
  },
  {
    imgSrc: Allow_Reset_Permission,
    imgAlt: "Allow Reset Permission",
    title: "Allow Reset Permission",
    description: "Reset permissions conveniently through the app, ensuring effortless management and control over access settings for enhanced user privacy and security."
  },
  {
    imgSrc: Device_Hard_Reset,
    imgAlt: "Device Hard Reset",
    title: "Device Hard Reset",
    description: "Easily reset your device using our app, restoring factory settings with a simple tap for seamless performance maintenance and troubleshooting."
  },
];


const ServiceSection = () => {
  return (
    <div className="section harsafe-servicess-section section-padding">
      <div className="container">
        <div className="services-wrap">
          <div className="section-title text-center">
            <h3 className="sub-title">Services</h3>
            <h2 className="title">Affordable Services for all</h2>
          </div>
          <div className="service-content-wrap">
            <div className="row right_choice_inner best_choice_inner mt-3">
              {services.map((service, index) => (
                <div key={index} className="col-lg-4 col-sm-6">
                  <div className="counter-item choice-item grayscale_img">
                    <div className="modalBoximg">
                      <img src={service.imgSrc} alt={service.imgAlt} />
                    </div>
                    <h4>{service.title}</h4>
                    <p>{service.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
