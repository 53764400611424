import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Service from './Pages/Service/Service'
import ContactUs from './Pages/ContactUs/ContactUs'
import AboutUs from './Pages/AboutUs/AboutUs'
import Blog from './Pages/Blog/Blog'
import Blog_Details from './Pages/Blog_Details/Blog_Details'
import OurTeam from './Pages/OurTeam/OurTeam'
import DShield from './Pages/ServiceDetails/ServiceDetails1'
import SchoolMS from './Pages/SchoolMS/SchoolMS'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "Service",
    element: <Service/>,
  },
  {
    path: "ContactUs",
    element: <ContactUs/>,
  },
  {
    path: "AboutUs",
    element: <AboutUs/>,
  },
  {
    path: "Blog",
    element: <Blog/>,
  },
  {
    path: "Blog_Details",
    element: <Blog_Details/>,
  },
  {
    path: "OurTeam",
    element: <OurTeam/>,
  },
  {
    path: "DShield",
    element: <DShield/>,
  },
  {
    path: "SchoolMS",
    element: <SchoolMS/>,
  },
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />



  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
