import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import OurTeamHero from './OurTeamHero'
import OurTeamMain from './OurTeamMain'
import Footer from '../../Components/Footer/Footer';

const OurTeam = () => {
  return (
    <div>
    <Navbar></Navbar>
       <OurTeamHero></OurTeamHero>
        <OurTeamMain></OurTeamMain>
        <Footer></Footer>
        </div>
  )
}

export default OurTeam