import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import SchoolMS_Hero from "../SchoolMS/SchoolMS_Hero/SchoolMS_Hero";
import Features_SMS from "./Features_SMS/Features_SMS";
import FeaturesSpecial from "./FeaturesSpecial/FeaturesSpecial";
// import SSSlider from "./SSSlider/SSSlider";
// import Skill from "./Skill/Skill";
import Testimonial from "./TestimonialSMS";
import Counter_Exp from "./Counter_Exp/Counter_Exp";
// import Pricing from "./Pricing/Pricing";
import Faq from "../SchoolMS/SchoolMS_FAQ/SchoolMS_FAQ";
import Footer from "../../Components/Footer/Footer";

const SchoolMS = () => {
  return (
    <div>
      <Navbar></Navbar>
      <SchoolMS_Hero></SchoolMS_Hero>
      <Features_SMS></Features_SMS>
      <FeaturesSpecial></FeaturesSpecial>
      {/* <Skill></Skill> */}
      {/* <SSSlider></SSSlider> */}
      <Testimonial></Testimonial>
      <Counter_Exp></Counter_Exp>
      <Faq></Faq>
      {/* <Pricing></Pricing> */}
      <Footer></Footer>
    </div>
  );
};

export default SchoolMS;
