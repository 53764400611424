import React, { useState } from "react";
import "../Skill/Skill.css";
import TextCounter from '../../../Components/counterwidget/TextCounter';


const Skill = () => {
  return (
    <div class="section harsafe-skill-section-02 section-padding harsafe-skill-sectionBG">
      <div class="container">
        <div class="skill-wrap">
          <div class="row">
            <div class="col-lg-6">
              {/* <!-- Skill Left Start --> */}
              <div class="skill-left">
                <div class="section-title">
                  <h2 class="title">Ensured Our Success with Superior Mobile EMI Safety Solutions</h2>
                </div>
                <div class="experience-wrap">
                  <div class="experience">
                    <h2 class="number"> <TextCounter value={3} />+</h2>
                    <span>Years of experience</span>
                  </div>
                  <div class="experience-text">
                    <p>Accelerate innovation with world-class teams. We’ll match you to an incredible Safety App for all your Mobile EMI Safety needs.</p>
                    {/* <a class="learn-more" href="#">
                      learn More About Us{" "}
                      <i class="fas fa-long-arrow-alt-right"></i>
                    </a> */}
                  </div>
                </div>
              </div>
              {/* <!-- Skill Left End --> */}
            </div>
            <div class="col-lg-6">
              {/* <!-- Skill Right Start --> */}
              <div class="skill-right">
                <div class="row gy-5">
                  <div class="col-md-6 col-sm-6 ssd">
                    <div class="counter">
                      <div class="counter-icon">
                        <span>
                          <i class="fa fa-briefcase"></i>
                        </span>
                      </div>
                      <h3>Stockist</h3>
                      <span class="counter-value"> <TextCounter value={10} />+</span>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <div class="counter green">
                      <div class="counter-icon">
                        <span>
                          <i class="fa fa-globe"></i>
                        </span>
                      </div>
                      <h3>Retailer</h3>
                      <span class="counter-value"> <TextCounter value={2000} />+</span>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <div class="counter purple">
                      <div class="counter-icon">
                        <span>
                          <i class="fa fa-globe"></i>
                        </span>
                      </div>
                      <h3>Customer</h3>
                      <span class="counter-value"> <TextCounter value={50} />K+</span>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <div class="counter blue">
                      <div class="counter-icon">
                        <span>
                          <i class="fa fa-globe"></i>
                        </span>
                      </div>
                      <h3>Success Ratio</h3>
                      <span class="counter-value"> <TextCounter value={98} />%</span>
                    </div>
                  </div>

                </div>
              </div>
              {/* <!-- Skill Right End --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default Skill;
