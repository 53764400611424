import React, { useState } from "react";
import SchoolMS_FAQs from "../../ServiceDetails/Faq/Faq"


const AccordionItem = ({ question, answer, isOpen, onClick }) => {
    return (
      <div className="accordion-item">
        <div className="accordion-header" id={question}>
          <button
            className={`accordion-button ${isOpen ? "" : "collapsed"}`}
            type="button"
            onClick={onClick}
            aria-expanded={isOpen}
            aria-controls={question}
          >
            <span className="title">{question}</span>
          </button>
        </div>
        <div
          id={question}
          className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
          aria-labelledby={question}
        >
          <div className="accordion-body">{answer}</div>
        </div>
      </div>
    );
  };

const SchoolMS_FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

  const items = [
    {
      question: "Is the School Management system compatible with boarding schools?",
      answer:
        "Harsafe school management system is compatible, irrespective of the type of school or content - helping administrators cut down manual efforts and run the institution efficiently.",
    },
    {
      question: "How does a school management system work?",
      answer:
        "Harsafe includes a comprehensive set of administrative tools required to digitize a school. Hence, the School ERP and LMS, which constitute the software, work together to give a smoothly functioning school with all respective systems in unison, providing a satisfying user experience.",
    },
    {
      question: "Why use a school management system?",
      answer:
        "Harsafe makes it efficient to manage all activities associated with the institution. This is driven by increased productivity, reduced cost and accurate data to refer to, with no risk of data loss.",
    },
    {
      question: "How does the school management software help schools?",
      answer:
        "Harsafe software for school management has a cutting-edge LMS, comprehensive digital content, and advanced ERP with 20+ modules. These components enhance the teaching-learning experience, boost productivity, and improve classroom collaboration.",
    },
    {
      question: "How does school management system software improve the learning process?",
      answer:
        "The learning management system ensures seamless learning for students by enabling management of schedule, allocation of tasks & study materials, creation and administration of tests, real-time communication, notifications & alerts and detailed analytics of student performance.",
    },
  ];

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
    

  return (
    <div className="section harsafe-choose-us-section-02 section-padding">
      <div className="container">
        {/* <!-- Pricing Wrap Start --> */}
        <div className="pricing-wrap">
          <div className="section-title text-center">
            <h3 className="sub-title">FAQ</h3>
            <h2 className="title">FAQ's</h2>
          </div>
          <div className="pricing-content-wrap">
            <div className="col-lg-8 col-md-12 m-auto">

              <div className="choose-us-right">
                <div className="faq-accordion">
                  <div className="accordion" id="accordionExample">
                    {items.map((item, index) => (
                      <AccordionItem
                        key={index}
                        question={item.question}
                        answer={item.answer}
                        isOpen={activeIndex === index}
                        onClick={() => handleClick(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              

            </div>
          </div>
        </div>
        {/* <!-- Pricing Wrap End --> */}
      </div>
    </div>
  )
}

export default SchoolMS_FAQ