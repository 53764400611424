import React from 'react'
import '../OurProduct/OurProduct.css'
import NextIcon from '../../Img/next-icon.png';
import SerIcon5 from '../../Img/ser-icon5.png';
import SerIcon6 from '../../Img/ser-icon6.png';
import SerIcon7 from '../../Img/ser-icon7.png';
import SerIcon8 from '../../Img/ser-icon8.png';

const OurProduct = () => {
  return (
    <div class="section harsafe-service-section-02 section-padding service-bg">
        <div class="container">
            {/* <!-- Service Wrap Start --> */}
            <div class="service-wrap">
                <div class="section-title text-center">
                    <h3 class="sub-title color-2">Our Products</h3>
                    <h2 class="title">Solutions for your Work</h2>
                </div>
                {/* <!-- Service Content Wrap Start --> */}
                <div class="service-content-wrap">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 mb-4">
                            {/* <!-- Service Item Start --> */}
                            <div class="service-item-02">
                                <a href="service.html">
                                    <div class="service-img">
                                        <img src={SerIcon5} alt=""/>
                                    </div>
                                    <div class="next-icon">
                                        <img src={NextIcon} alt=""/>
                                    </div>
                                </a>
                                <div class="service-content">
                                    <h3 class="title"><a href="service.html">Web Development</a></h3>
                                    <p>Accelerate innovation with world-class tech teams We’ll match you to an entire
                                        remote team .</p>
                                </div>
                            </div>
                            {/* <!-- Service Item End --> */}
                        </div>
                        <div class="col-xl-3 col-sm-6 mb-4">
                            {/* <!-- Service Item Start --> */}
                            <div class="service-item-02 service-2">
                                <a href="service.html">
                                    <div class="service-img">
                                        <img src={SerIcon6} alt=""/>
                                    </div>
                                    <div class="next-icon">
                                        <img src={NextIcon} alt=""/>
                                    </div>
                                </a>
                                <div class="service-content">
                                    <h3 class="title"><a href="service.html">Mobile App Development</a></h3>
                                    <p>Accelerate innovation with world-class tech teams We’ll match you to an entire
                                        remote team .</p>
                                </div>
                            </div>
                            {/* <!-- Service Item End --> */}
                        </div>
                        <div class="col-xl-3 col-sm-6 mb-4">
                            {/* <!-- Service Item Start --> */}
                            <div class="service-item-02">
                                <a href="service.html">
                                    <div class="service-img">
                                        <img src={SerIcon7} alt=""/>
                                    </div>
                                    <div class="next-icon">
                                        <img src={NextIcon} alt=""/>
                                    </div>
                                </a>
                                <div class="service-content">
                                    <h3 class="title"><a href="service.html">Software Innovation</a></h3>
                                    <p>Accelerate innovation with world-class tech teams We’ll match you to an entire
                                        remote team .</p>
                                </div>
                            </div>
                            {/* <!-- Service Item End --> */}
                        </div>
                        <div class="col-xl-3 col-sm-6 mb-4">
                            {/* <!-- Service Item Start --> */}
                            <div class="service-item-02 service-4">
                                <a href="service.html">
                                    <div class="service-img">
                                        <img src={SerIcon8} alt=""/>
                                    </div>
                                    <div class="next-icon">
                                        <img src={NextIcon} alt=""/>
                                    </div>
                                </a>
                                <div class="service-content">
                                    <h3 class="title"><a href="service.html">App Management System</a></h3>
                                    <p>Accelerate innovation with world-class tech teams We’ll match you to an
                                        entire remote team .</p>
                                </div>
                            </div>
                            {/* <!-- Service Item End --> */}
                        </div>
                        <div class="col-xl-3 col-sm-6 mb-4">
                            {/* <!-- Service Item Start --> */}
                            <div class="service-item-02">
                                <a href="service.html">
                                    <div class="service-img">
                                        <img src={SerIcon8} alt=""/>
                                    </div>
                                    <div class="next-icon">
                                        <img src={NextIcon} alt=""/>
                                    </div>
                                </a>
                                <div class="service-content">
                                    <h3 class="title"><a href="service.html">App Management System</a></h3>
                                    <p>Accelerate innovation with world-class tech teams We’ll match you to an
                                        entire remote team .</p>
                                </div>
                            </div>
                            {/* <!-- Service Item End --> */}
                        </div>
                        <div class="col-xl-3 col-sm-6 mb-4">
                            {/* <!-- Service Item Start --> */}
                            <div class="service-item-02 service-4">
                                <a href="service.html">
                                    <div class="service-img">
                                        <img src={SerIcon8} alt=""/>
                                    </div>
                                    <div class="next-icon">
                                        <img src={NextIcon} alt=""/>
                                    </div>
                                </a>
                                <div class="service-content">
                                    <h3 class="title"><a href="service.html">App Management System</a></h3>
                                    <p>Accelerate innovation with world-class tech teams We’ll match you to an
                                        entire remote team .</p>
                                </div>
                            </div>
                            {/* <!-- Service Item End --> */}
                        </div>
                        <div class="col-xl-3 col-sm-6 mb-4">
                            {/* <!-- Service Item Start --> */}
                            <div class="service-item-02">
                                <a href="service.html">
                                    <div class="service-img">
                                        <img src={SerIcon8} alt=""/>
                                    </div>
                                    <div class="next-icon">
                                        <img src={NextIcon} alt=""/>
                                    </div>
                                </a>
                                <div class="service-content">
                                    <h3 class="title"><a href="service.html">App Management System</a></h3>
                                    <p>Accelerate innovation with world-class tech teams We’ll match you to an
                                        entire remote team .</p>
                                </div>
                            </div>
                            {/* <!-- Service Item End --> */}
                        </div>
                    </div>
                     {/* <div class="row">
                        <div class="col-lg-12">
                            <div class="more-service-content text-center">
                                <p>Learn more about <a href="service.html">More Services <i
                                            class="fas fa-long-arrow-alt-right"></i></a></p>
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <!-- Service Content Wrap End --> */}
            </div>
            {/* <!-- Service Wrap End --> */}
        </div>
    </div>
  )
}

export default OurProduct