import React from "react";
import "../Feature/Feature.css";

const features = [
  {
    id: 1,
    title: "Advanced Technology",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aspernatur fugiat aut veniam perspiciatis. Quasi doloremque vitae voluptas reiciendis ducimus!",
  },
  {
    id: 2,
    title: "Reliable Security",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aspernatur fugiat aut veniam perspiciatis. Quasi doloremque vitae voluptas reiciendis ducimus!",
  },
  {
    id: 3,
    title: "User-Friendly Interface",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aspernatur fugiat aut veniam perspiciatis. Quasi doloremque vitae voluptas reiciendis ducimus!",
  },
  {
    id: 4,
    title: "Scalable Infrastructure",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aspernatur fugiat aut veniam perspiciatis. Quasi doloremque vitae voluptas reiciendis ducimus!",
  },
  {
    id: 5,
    title: "24/7 Support",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aspernatur fugiat aut veniam perspiciatis. Quasi doloremque vitae voluptas reiciendis ducimus!",
  },
  {
    id: 6,
    title: "Cost Efficiency",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aspernatur fugiat aut veniam perspiciatis. Quasi doloremque vitae voluptas reiciendis ducimus!",
  },
];

const Feature = () => {
  return (
    <div className="section harsafe-servicess-section pt-60">
      <div className="container">
        <div className="services-wrap">
          <div className="section-title text-center">
            <h3 className="sub-title">Features</h3>
            <h2 className="title">Key Features Overview</h2>
          </div>
          <div className="strengthSec">
            <div className="row lt-ued">
              {features.map((feature) => (
                <div key={feature.id} className="col-md-6 col-xl-4 col-sm-6 mb-4">
                  <div className="cb-challenges">
                    <span className="chicon-color">{feature.id}</span>
                    <h5>{feature.title} <span className="bordy"></span></h5>
                    <p>{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
