import React from 'react'
import '../CEOSection/CEOSection.css'

const CEOSection = () => {
  return (
    <div class="section harsafe-about-section-02 section-padding">
        <div class="container">
            {/* <!-- About Wrapper Start --> */}
            <div class="about-wrap">
                <div class="row">
                    <div class="col-lg-6">
                        {/* <!-- About Left Start --> */}
                        <div class="about-02-left text-center">
                            <div class="section-title">
                                <h2 class="title">You know you did right when all your effort started to pay off in an
                                    unexpected and impressive way.</h2>
                            </div>
                            <div class="about-author">
                                {/* <!-- <img src="assets/images/sign.png" alt=""> --> */}
                                <h3 class="name">Alen Morno sin</h3>
                                <span class="designation">CEO, Harsafe</span>
                            </div>
                        </div>
                        {/* <!-- About Left End --> */}
                    </div>
                    <div class="col-lg-6">
                        {/* <!-- About Right Start --> */}
                        <div class="about-02-right">
                            <p>Accelerate innovation with world-class tech teams We’ll match you to an entire remote
                                team of incredible freelance talent for all your software development needs.</p>
                            <div class="about-list">
                                <ul>
                                    <li>
                                        <span class="about-icon"><i class="fas fa-check"></i></span>
                                        <span class="about-text">We always focus on technical excellence </span>
                                    </li>
                                    <li>
                                        <span class="about-icon"><i class="fas fa-check"></i></span>
                                        <span class="about-text"> Wherever you’re going, we bring ideas and excitement
                                        </span>
                                    </li>
                                    <li>
                                        <span class="about-icon"><i class="fas fa-check"></i></span>
                                        <span class="about-text">We’re consultants, guides, and partners for brands
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- About Right End --> */}
                    </div>
                </div>
            </div>
            {/* <!-- About Wrapper End --> */}
        </div>
    </div>
  )
}

export default CEOSection