// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'swiper/css';
import 'bootstrap/js/dist/carousel.js'
// import '../node_modules/'
// import $ from 'jquery'
import 'jquery-ui-dist/jquery-ui.js'
import {useEffect} from 'react'
import Navbar from './Components/Navbar/Navbar.jsx';
import Hero from './Components/Hero/Hero.jsx';
import Brand_Logo from './Components/Brand_Logo/Brand_Logo.jsx';
import PartnerSIT from './Components/PartnerSIT/PartnerSIT.jsx';
import We_Provide from './Components/We_Provide/We_Provide.jsx';
import WhoWeAre from './Components/WhoWeAre/WhoWeAre.jsx';
import OurProduct from './Components/OurProduct/OurProduct.jsx';
import CEOSection from './Components/CEOSection/CEOSection.jsx';
import Counter from './Components/Counter/Counter.jsx';
import ContactInfo from './Components/ContactInfo/ContactInfo.jsx';
import Footer from './Components/Footer/Footer.jsx';
// import './js/index.js'

function App() {
  return (

// useEffect(() => {

// },[])

    <div className="App">
      <Navbar/>
      <Hero/>
      {/* <Brand_Logo/> */}
      <PartnerSIT/>
      <We_Provide/>
      <WhoWeAre/>
      <OurProduct/>
      <CEOSection/>
      <Counter/>
      <ContactInfo/>
      <Footer/>
    </div>
  );
}

export default App;
