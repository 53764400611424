import React from 'react'
import './ContactContactInfo.css'
import Info1 from '../../../Img/info-1.png';
import Info2 from '../../../Img/info-2.png';
import Info3 from '../../../Img/info-3.png';



const ContactContactInfo = () => {
  return (
    <div className="main">
    <div class="section contact-info-section section-padding-02">
            <div class="container">
                {/* <!-- Contact Info Wrap Start --> */}
                <div class="contact-info-wrap">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            {/* <!--Single Contact Info Start --> */}
                            <div class="single-contact-info text-center">
                                <div class="info-icon">
                                    <img src={Info1} alt=""/>
                                </div>
                                <div class="info-content">
                                    <h5 class="title">Give us a call</h5>
                                    <p><a href="tel:+919351734009" target='_blank'>+91 9351 734 009</a></p>
                                </div>
                            </div>
                            {/* <!--Single Contact Info End --> */}
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            {/* <!--Single Contact Info Start --> */}
                            <div class="single-contact-info text-center">
                                <div class="info-icon">
                                <img src={Info2} alt=""/>
                                </div>
                                <div class="info-content">
                                    <h5 class="title">Drop us a line</h5>
                                    <p>info@harsafe.com</p>
                                </div>
                            </div>
                            {/* <!--Single Contact Info End --> */}
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            {/* <!--Single Contact Info Start --> */}
                            <div class="single-contact-info text-center">
                                <div class="info-icon">
                                <img src={Info3} alt=""/>
                                </div>
                                <div class="info-content">
                                    <h5 class="title">Visit our office</h5>
                                    <p>Bawari Road, Shrimadhopur, Sikar Rajasthan (332715)</p>
                                    {/* <p>Behind Deputy Office,Reengus Sikar Rajasthan (332403)</p> */}
                                </div>
                            </div>
                            {/* <!--Single Contact Info End --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- Contact Info Wrap End --> */}
            </div>
        </div>

        <div class="section harsafe-contact-section harsafe-contact-section-02 harsafe-contact-section-03 section-padding-02">
            <div class="container">
                {/* <!-- Contact Wrap Start --> */}
                <div class="contact-wrap">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            {/* <!-- Contact Form Start --> */}
                            <div class="contact-form">
                                <div class="contact-form-wrap">
                                    <div class="heading-wrap text-center">
                                        <span class="sub-title"> request a quote</span>
                                        <h3 class="title">How May We Help You!</h3>
                                    </div>
                                    <form action="#">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                {/* <!-- Single Form Start --> */}
                                                <div class="single-form">
                                                    <input type="text" placeholder="Name *"/>
                                                </div>
                                                {/* <!-- Single Form End --> */}
                                            </div>
                                            <div class="col-sm-6">
                                                {/* <!-- Single Form Start --> */}
                                                <div class="single-form">
                                                    <input type="email" placeholder="Email *"/>
                                                </div>
                                                {/* <!-- Single Form End --> */}
                                            </div>
                                            <div class="col-sm-12">
                                                {/* <!-- Single Form Start --> */}
                                                <div class="single-form">
                                                    <input type="text" placeholder="Subject *"/>
                                                </div>
                                                {/* <!-- Single Form End --> */}
                                            </div>
                                            <div class="col-sm-12">
                                                {/* <!-- Single Form Start --> */}
                                                <div class="single-form">
                                                    <textarea placeholder="Write A Message"></textarea>
                                                </div>
                                                {/* <!-- Single Form End --> */}
                                            </div>
                                            <div class="col-sm-12">
                                                {/* <!--  Single Form Start --> */}
                                                <div class="form-btn">
                                                    <button class="btn" type="submit">Send Message</button>
                                                </div>
                                                {/* <!--  Single Form End --> */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* <!-- Contact Form End --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- Contact Wrap End --> */}
            </div>
        </div>

        {/* <!-- Contact Map Start --> */}
        <div class="section contact-map-section map-padding">
            <div class="contact-map-wrap">
                <iframe scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=New%20York%20University%20&t=m&z=10&output=embed&iwloc=near" title="New York University" aria-label="New York University" frameborder="0"></iframe>
            </div>
        </div>
        {/* <!-- Contact Map End --> */}


        </div>
  )
}

export default ContactContactInfo