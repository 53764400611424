import React from "react";
import "./ServiceM.css";
import ServiceIcon1 from "../../Img/services/service-icon-1.png";
import ServiceIcon2 from "../../Img/services/service-icon-2.png";
import ServiceIcon3 from "../../Img/services/service-icon-3.png";
import ServiceIcon4 from "../../Img/services/service-icon-4.png";
import ServiceIcon5 from "../../Img/services/service-icon-5.png";
import ServiceIcon6 from "../../Img/services/service-icon-6.png";
import { Link } from "react-router-dom";


const services = [
  {
    icon: ServiceIcon1,
    title: "DShield Pro",
    description: "DShield Pro is a Complete Solution of Your EMI Safety.",
    link: "/DShield"
  },
  {
    icon: ServiceIcon2,
    title: "School Management System",
    description: "A School Management System efficiently handles administrative tasks, student records, scheduling, and communication, enhancing overall school operations and productivity.",
    link: "/SchoolMS"
  },
  {
    icon: ServiceIcon3,
    title: "Chat App",
    description: "Our chat app enables real-time messaging, file sharing, and group conversations, enhancing communication and collaboration for personal, educational, or professional use.",
    link: "#"
  },
  {
    icon: ServiceIcon4,
    title: "Harsafe Pay",
    description: "Harsafe Pay app offers seamless, secure transactions and financial management, ensuring effortless payments, real-time tracking, and robust security for a smooth user experience.",
    link: "#"
  },

];


const ServiceM = () => {
  return (
    <section className="section section-padding harsafe-service-section">
      <div className="container">
        <div className="section-title text-center">
          <h3 className="sub-title">Our Services</h3>
          <h2 className="title">We Truly Provide Services That Make Your Life Easier</h2>
        </div>
        <div className="row mt-5">
          {services.map((service, index) => (
            <div key={index} className="col-md-6 col-sm-12 mb-3">
              <div className="icon-box text-center hero-bg">
                <div className="service-icon">
                  <img src={service.icon} alt={service.title} />
                </div>
                <h6>
                  <Link to={service.link}>{service.title}</Link>
                </h6>
                <p>{service.description}</p>
                <Link to={service.link} className="btn-link">
                  {service.link!=="#"?"Read More":"Comming Soon"}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceM;
