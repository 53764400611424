import React from "react";
import "../ServiceDetails/Testimonial/Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper/modules";
import { EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/swiper-bundle.css";

import TestimonialIcon from "../../Img/testi-icon.png";
import Testimonial1 from "../../Img/testi-3.jpg";

const testimonials = [
  {
    image: Testimonial1,
    text: "Dshield pro is the perfect companion for managing EMI. Its lock unlock features are intuitive and efficient, saving my time and worry.",
    name: "Vinayak Mobile Shahpura",
    designation: "(Authorize Adviser)"
  },
  {
    image: Testimonial1,
    text: "Accelerate innovation with world-class tech teams. Beyond more stoic this along goodness hey this this wow manatee",
    name: "Mike Fermalin",
    designation: "/ CEO, Harlond inc"
  },
  {
    image: Testimonial1,
    text: "Accelerate innovation with world-class tech teams. Beyond more stoic this along goodness hey this this wow manatee",
    name: "Mike Fermalin",
    designation: "/ CEO, Harlond inc"
  },
  // Add more testimonials as needed
];
const TestimonialSMS = () => {

  return (
    <div class="section bg-cover harsafe-testimonial-section-02 section-padding">
      <div class="container">
        {/* <!-- Testimonial Wrap Start  --> */}
        <div class="testimonial-wrap-02">
          <div class="section-title text-center">
            <h3 class="sub-title">Testimonial</h3>
            <h2 class="title">20k+ satisfied clients worldwide</h2>
          </div>
          <div class="testimonial-content-wrap-02">
            <div class="swiper-container testimonial-02-active">
              <Swiper
                // install Swiper modules
                modules={[Pagination, A11y]}
                spaceBetween={50}
                slidesPerView={2}
                loop={true}
                effect="fade"
speed={900}
                // navigation
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log("slide change")}
                breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    639: {
                      slidesPerView: 1,
                    },
                    1400:{
                      slidesPerView:2
                    }
                  }}
              >
                
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <div className="swiper-slide">
                      {/* Single Testimonial Start */}
                      <div className="single-testimonial-02">
                        <div className="testimonial-thumb">
                          <img src={testimonial.image} alt={testimonial.name} />
                        </div>
                        <div className="testimonial-content">
                          <img src={TestimonialIcon} alt="Icon" />
                          <p>{testimonial.text}</p>
                          <span className="name">{testimonial.name}</span>
                          <span className="designation">{testimonial.designation}</span>
                        </div>
                      </div>
                      {/* Single Testimonial End */}
                    </div>
                  </SwiperSlide>
                ))}


                {/* <SwiperSlide>Slide 4</SwiperSlide> */}

              </Swiper>

              {/* <!-- Add Pagination --> */}
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
        {/* <!-- Testimonial Wrap End  --> */}
      </div>
    </div>
  );
};

export default TestimonialSMS;
