import React from 'react'
import '../Blog/BlogMain.css'
import Blog1 from '../../Img/blog/blog-1.jpg'
import Blog2 from '../../Img/blog/blog-2.jpg'
import Blog3 from '../../Img/blog/blog-3.jpg'
import Blog4 from '../../Img/blog/blog-4.jpg'
import Blog5 from '../../Img/blog/blog-5.jpg'
import Blog6 from '../../Img/blog/blog-6.jpg'
import Blog7 from '../../Img/blog/blog-7.jpg'
import Blog8 from '../../Img/blog/blog-8.jpg'
import Blog9 from '../../Img/blog/blog-9.jpg'
import R_Post1 from '../../Img/blog/r-post1.jpg'
import R_Post2 from '../../Img/blog/r-post2.jpg'
import R_Post3 from '../../Img/blog/r-post3.jpg'
import { Link } from "react-router-dom";

const BlogMain = () => {
  return (
    
<div class="section harsafe-blog-grid-section section-padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="harsafe-blog-grid-wrap">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                {/* <!-- Single Blog Start --> */}
                                <div class="single-blog">
                                    <div class="blog-image">
                                    <Link to='/Blog_Details'><img src={Blog1} alt=""/></Link>
                                        <div class="top-meta">
                                            <span class="date"><span>08</span>Aug</span>
                                        </div>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span><i class="fas fa-user"></i> <a href="#">Andrew Paker</a></span>
                                            <span><i class="far fa-comments"></i> 0 Comments</span>
                                        </div>
                                        <h3 class="title"><Link to='/Blog_Details'>How to become a successful
                                                businessman
                                            </Link></h3>
                                        <div class="blog-btn">
                                            <a class="blog-btn-link" href="blog-details.html">Read Full <i
                                                    class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Single Blog End --> */}
                            </div>
                            <div class="col-lg-6 col-md-6">
                                {/* <!-- Single Blog Start --> */}
                                <div class="single-blog">
                                    <div class="blog-image">
                                        <Link to='/Blog_Details'><img src={Blog2} alt=""/></Link>
                                        <div class="top-meta">
                                            <span class="date"><span>10</span>Aug</span>
                                        </div>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span><i class="fas fa-user"></i> <a href="#">Andrew Paker</a></span>
                                            <span><i class="far fa-comments"></i> 0 Comments</span>
                                        </div>
                                        <h3 class="title"><Link to='/Blog_Details'>Who Needs Extract Value From Data?
                                            </Link></h3>
                                        <div class="blog-btn">
                                            <a class="blog-btn-link" href="blog-details.html">Read Full <i
                                                    class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Single Blog End --> */}
                            </div>
                            <div class="col-lg-6 col-md-6">
                                {/* <!-- Single Blog Start --> */}
                                <div class="single-blog">
                                    <div class="blog-image">
                                        <Link to='/Blog_Details'><img src={Blog3} alt=""/></Link>
                                        <div class="top-meta">
                                            <span class="date"><span>12</span>Aug</span>
                                        </div>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span><i class="fas fa-user"></i> <a href="#">Andrew Paker</a></span>
                                            <span><i class="far fa-comments"></i> 0 Comments</span>
                                        </div>
                                        <h3 class="title"><Link to='/Blog_Details'>Easy and Most Powerful Server and
                                                Platform.</Link></h3>
                                        <div class="blog-btn">
                                            <a class="blog-btn-link" href="blog-details.html">Read Full <i
                                                    class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Single Blog End --> */}
                            </div>
                            <div class="col-lg-6 col-md-6">
                                {/* <!-- Single Blog Start --> */}
                                <div class="single-blog">
                                    <div class="blog-image">
                                        <Link to='/Blog_Details'><img src={Blog4} alt=""/></Link>
                                        <div class="top-meta">
                                            <span class="date"><span>10</span>Aug</span>
                                        </div>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span><i class="fas fa-user"></i> <a href="#">Andrew Paker</a></span>
                                            <span><i class="far fa-comments"></i> 0 Comments</span>
                                        </div>
                                        <h3 class="title"><Link to='/Blog_Details'>Who Needs Extract Value From Data?
                                            </Link></h3>
                                        <div class="blog-btn">
                                            <a class="blog-btn-link" href="blog-details.html">Read Full <i
                                                    class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Single Blog End --> */}
                            </div>
                            <div class="col-lg-6 col-md-6">
                                {/* <!-- Single Blog Start --> */}
                                <div class="single-blog">
                                    <div class="blog-image">
                                        <Link to='/Blog_Details'><img src={Blog5} alt=""/></Link>
                                        <div class="top-meta">
                                            <span class="date"><span>12</span>Aug</span>
                                        </div>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span><i class="fas fa-user"></i> <a href="#">Andrew Paker</a></span>
                                            <span><i class="far fa-comments"></i> 0 Comments</span>
                                        </div>
                                        <h3 class="title"><Link to='/Blog_Details'>Easy and Most Powerful Server and
                                                Platform.</Link></h3>
                                        <div class="blog-btn">
                                            <a class="blog-btn-link" href="blog-details.html">Read Full <i
                                                    class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Single Blog End --> */}
                            </div>
                            <div class="col-lg-6 col-md-6">
                                {/* <!-- Single Blog Start --> */}
                                <div class="single-blog">
                                    <div class="blog-image">
                                        <Link to='/Blog_Details'><img src={Blog6} alt=""/></Link>
                                        <div class="top-meta">
                                            <span class="date"><span>08</span>Aug</span>
                                        </div>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span><i class="fas fa-user"></i> <a href="#">Andrew Paker</a></span>
                                            <span><i class="far fa-comments"></i> 0 Comments</span>
                                        </div>
                                        <h3 class="title"><Link to='/Blog_Details'>How to become a successful
                                                businessman
                                            </Link></h3>
                                        <div class="blog-btn">
                                            <a class="blog-btn-link" href="blog-details.html">Read Full <i
                                                    class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Single Blog End --> */}
                            </div>
                            <div class="col-lg-6 col-md-6">
                                {/* <!-- Single Blog Start --> */}
                                <div class="single-blog">
                                    <div class="blog-image">
                                        <Link to='/Blog_Details'><img src={Blog7} alt=""/></Link>
                                        <div class="top-meta">
                                            <span class="date"><span>08</span>Aug</span>
                                        </div>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span><i class="fas fa-user"></i> <a href="#">Andrew Paker</a></span>
                                            <span><i class="far fa-comments"></i> 0 Comments</span>
                                        </div>
                                        <h3 class="title"><Link to='/Blog_Details'>How to become a successful
                                                businessman
                                            </Link></h3>
                                        <div class="blog-btn">
                                            <a class="blog-btn-link" href="blog-details.html">Read Full <i
                                                    class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Single Blog End --> */}
                            </div>
                            <div class="col-lg-6 col-md-6">
                                {/* <!-- Single Blog Start --> */}
                                <div class="single-blog">
                                    <div class="blog-image">
                                        <Link to='/Blog_Details'><img src={Blog8} alt=""/></Link>
                                        <div class="top-meta">
                                            <span class="date"><span>10</span>Aug</span>
                                        </div>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span><i class="fas fa-user"></i> <a href="#">Andrew Paker</a></span>
                                            <span><i class="far fa-comments"></i> 0 Comments</span>
                                        </div>
                                        <h3 class="title"><Link to='/Blog_Details'>Who Needs Extract Value From Data?
                                            </Link></h3>
                                        <div class="blog-btn">
                                            <a class="blog-btn-link" href="blog-details.html">Read Full <i
                                                    class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Single Blog End --> */}
                            </div>
                            <div class="col-lg-6 col-md-6">
                                {/* <!-- Single Blog Start --> */}
                                <div class="single-blog">
                                    <div class="blog-image">
                                        <Link to='/Blog_Details'><img src={Blog9} alt=""/></Link>
                                        <div class="top-meta">
                                            <span class="date"><span>12</span>Aug</span>
                                        </div>
                                    </div>
                                    <div class="blog-content">
                                        <div class="blog-meta">
                                            <span><i class="fas fa-user"></i> <a href="#">Andrew Paker</a></span>
                                            <span><i class="far fa-comments"></i> 0 Comments</span>
                                        </div>
                                        <h3 class="title"><Link to='/Blog_Details'>Easy and Most Powerful Server and
                                                Platform.</Link></h3>
                                        <div class="blog-btn">
                                            <a class="blog-btn-link" href="blog-details.html">Read Full <i
                                                    class="fas fa-long-arrow-alt-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Single Blog End --> */}
                            </div>
                        </div>
                        {/* <!-- Page Pagination Start --> */}
                        <div class="harsafe-pagination">
                            <ul class="pagination justify-content-center">
                                <li><a href="#"><i class="fa fa-angle-left"></i></a></li>
                                <li><a href="blog.html">1</a></li>
                                <li><a class="active" href="blog.html">2</a></li>
                                <li><a href="blog.html">3</a></li>
                                <li><span>...</span></li>
                                <li><a href="blog.html"><i class="fa fa-angle-right"></i></a></li>
                            </ul>
                        </div>
                        {/* <!-- Page Pagination End --> */}
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4">
                    {/* <!-- Blog Sidebar Start --> */}
                    <div class="blog-sidebar">
                        {/* <!-- Sidebar Widget Start --> */}
                        <div class="sidebar-widget sidebar-widget-1">
                            {/* <!-- Widget Search Form Start --> */}
                            <form class="search-form" action="#">
                                <input type="text" placeholder="Write your keyword..."/>
                                <button type="submit"><i class="fas fa-search"></i></button>
                            </form>
                            {/* <!-- Widget Search Form End --> */}
                        </div>
                        {/* <!-- Sidebar Widget End --> */}

                        {/* <!-- Sidebar Widget Start --> */}
                        <div class="sidebar-widget">
                            {/* <!-- Widget Title Start --> */}
                            <div class="widget-title">
                                <h3 class="title">Popular Posts</h3>
                            </div>
                            {/* <!-- Widget Title End --> */}
                            {/* <!-- Widget Recent Post Start --> */}
                            <div class="recent-posts">
                                <ul>
                                    <li>
                                        <a class="post-link" href="blog-details.html">
                                            <div class="post-thumb">
                                            <img src={R_Post1} alt=""/>
                                            </div>
                                            <div class="post-text">
                                                <h4 class="title">How Wireless Technology is Changing Business</h4>
                                                <span class="post-meta"><i class="far fa-calendar-alt"></i> May 15, 2020</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="post-link" href="blog-details.html">
                                            <div class="post-thumb">
                                            <img src={R_Post2} alt=""/>
                                            </div>
                                            <div class="post-text">
                                                <h4 class="title">How Wireless Technology is Changing Business</h4>
                                                <span class="post-meta"><i class="far fa-calendar-alt"></i> May 15, 2020</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="post-link" href="blog-details.html">
                                            <div class="post-thumb">
                                            <img src={R_Post3} alt=""/>
                                            </div>
                                            <div class="post-text">
                                                <h4 class="title">How Wireless Technology is Changing Business</h4>
                                                <span class="post-meta"><i class="far fa-calendar-alt"></i> May 15, 2020</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {/* <!-- Widget Recent Post End --> */}
                        </div>
                        {/* <!-- Sidebar Widget End --> */}

                        <div class="sidebar-widget">
                            <div class="widget-banner">
                                <div class="banner-content">
                                    <h4 class="title">The leading platform</h4>
                                    <a class="btn" href="contact.html">Get Started</a>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Sidebar Widget Start --> */}
                        <div class="sidebar-widget">
                            {/* <!-- Widget Title Start --> */}
                            <div class="widget-title">
                                <h3 class="title">Categories</h3>
                            </div>
                            {/* <!-- Widget Title End --> */}
                            {/* <!-- Widget Category Start --> */}
                            <ul class="category">
                                <li class="cate-item"><a href="blog.html"><i class="flaticon-next"></i> Technology <span class="post-count">3</span></a></li>
                                <li class="cate-item"><a href="blog.html"><i class="flaticon-next"></i> Innovation <span class="post-count">5</span></a></li>
                                <li class="cate-item"><a href="blog.html"><i class="flaticon-next"></i> Learning <span class="post-count">3</span></a></li>
                                <li class="cate-item"><a href="blog.html"><i class="flaticon-next"></i> Information <span class="post-count">3</span></a></li>
                            </ul>
                            {/* <!-- Widget Category End --> */}
                        </div>
                        {/* <!-- Sidebar Widget End --> */}

                        {/* <!-- Sidebar Widget Start --> */}
                        <div class="sidebar-widget">
                            {/* <!-- Widget Title Start --> */}
                            <div class="widget-title">
                                <h3 class="title">Tags</h3>
                            </div>
                            {/* <!-- Widget Title End --> */}
                            {/* <!-- Widget Category Start --> */}
                            <ul class="sidebar-tag">
                                <li><a href="#">Learning</a></li>
                                <li><a href="#">Course</a></li>
                                <li><a href="#">Business</a></li>
                                <li><a href="#">Udemy</a></li>
                                <li><a href="#">Online</a></li>
                                <li><a href="#">Technology</a></li>
                            </ul>
                            {/* <!-- Widget Category End --> */}
                        </div>
                        {/* <!-- Sidebar Widget End --> */}
                    </div>
                    {/* <!-- Blog Sidebar End --> */}
                </div>
            </div>
        </div>
    </div>


  )
}

export default BlogMain