import React from 'react'
import '../Counter/Counter.css'
import CountUp from 'react-countup';
import Counter1 from '../../Img/counter-1.png';
import Counter2 from '../../Img/counter-2.png';
import Counter3 from '../../Img/counter-3.png';

const Counter = () => {
  return (
    <div class="section harsafe-counter-section">
        <div class="container">
            <div class="counter-wrap">
                <div class="row">
                    <div class="col-lg-3 col-sm-6">
                        {/* <!-- Single Counter Start --> */}
                        <div class="single-counter">
                            <div class="counter-img">
                                <img src={Counter1} alt=""/>
                            </div>
                            <div class="counter-content">
                                <span class="counter">
                                    <CountUp
                                    duration={2.75}
                                    start={0}
                                    end={1792}>
                                    </CountUp>
                                    </span>
                                <p>Happy clients</p>
                            </div>
                        </div>
                        {/* <!-- Single Counter End --> */}
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        {/* <!-- Single Counter Start --> */}
                        <div class="single-counter">
                            <div class="counter-img">
                            <img src={Counter2} alt=""/>
                            </div>
                            <div class="counter-content">
                                <span class="counter">
                                    <CountUp
                                    duration={2.75}
                                    start={0}
                                    end={621}>
                                    </CountUp>
                                    </span>
                                <p>Finished projects</p>
                            </div>
                        </div>
                        {/* <!-- Single Counter End --> */}
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        {/* <!-- Single Counter Start --> */}
                        <div class="single-counter">
                            <div class="counter-img">
                            <img src={Counter3} alt=""/>
                            </div>
                            <div class="counter-content">
                                <span class="counter">
                                    <CountUp
                                    duration={2.75}
                                    start={0}
                                    end={220}>
                                    </CountUp>
                                    +</span>
                                <p>Skilled Experts</p>
                            </div>
                        </div>
                        {/* <!-- Single Counter End --> */}
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        {/* <!-- Single Counter Start --> */}
                        <div class="single-counter">
                            <div class="counter-img">
                            <img src={Counter1} alt=""/>
                            </div>
                            <div class="counter-content">
                                <span class="counter">
                                    <CountUp
                                    duration={2.75}
                                    start={0}
                                    end={1090}>
                                    </CountUp>
                                    </span>
                                <p>Media Posts</p>
                            </div>
                        </div>
                        {/* <!-- Single Counter End --> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Counter