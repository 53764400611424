import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import HeroContact from './HeroContact/HeroContact'
import ContactContactInfo from './ContactContactInfo/ContactContactInfo'
import Footer from '../../Components/Footer/Footer';

const ContactUs = () => {
  return (
    <div>
        <Navbar></Navbar>
        <HeroContact></HeroContact>
        <ContactContactInfo></ContactContactInfo>
        <Footer></Footer>
        
    </div>
  )
}

export default ContactUs