import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../WhoWe_About/WhoWe_About.css'
import About3 from '../../../Img/about-3.jpg'
import About4 from '../../../Img/about-4.jpg'
import AboutShape2 from '../../../Img/shape/about-shape2.png'
import {useState} from 'react'

const WhoWe_About = () => {
    const [toggle, setToggle] = useState(1)
    function updateToggle(id) {
        setToggle(id)
    }
  return (
    // <!-- About Start -->
    <div class="section harsafe-about-section-07 section-padding">
        <div class="shape-1"></div>
        <div class="container">
            {/* <!-- About Wrapper Start --> */}
            <div class="about-wrap">
                <div class="row">
                    <div class="col-lg-6">
                        {/* <!-- About Image Wrap Start --> */}
                        <div class="about-img-wrap">
                            <img class="shape-1" src={AboutShape2} alt=""/>
                            <div class="about-img">
                                <img src={About3} alt=""/>
                            </div>
                            <div class="about-img about-img-2">
                                <img src={About4} alt=""/>
                            </div>
                        </div>
                        {/* <!-- About Image Wrap End --> */}
                    </div>
                    <div class="col-lg-6">
                        {/* <!-- About Content Wrap Start --> */}
                        <div class="about-content-wrap">
                            <div class="section-title">
                                <h3 class="sub-title">Who we are</h3>
                                <h2 class="title">Highly Tailored IT Design, Management & Support Services.</h2>
                            </div>
                            <p class="text">Accelerate innovation with world-class tech teams We’ll match you to an
                                entire remote team of incredible freelance talent for all your software development
                                needs.</p>
                            {/* <!-- About List Start --> */}
                            <div class="about-list-03">

                                <div class="row">
                                    <div class="col-md-3">
                                        {/* <!-- Tabs nav --> */}
                                        <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab"
                                            role="tablist" aria-orientation="vertical">

                                            <a class="nav-link mb-3 p-3 shadow active" id="v-pills-mission-tab"
                                                data-bs-toggle="tab" data-bs-target="#v-pills-mission" role="tab"
                                                aria-controls="v-pills-mission" aria-selected="true"  onClick={() => updateToggle(1)} className={toggle === 1 ? "nav-link mb-3 p-3 shadow active" : "nav-link mb-3 p-3 shadow"}>
                                                Mission
                                                {/* <!-- <i class="fas fa-user-circle mr-2"></i> */}
                                                {/* <span class="font-weight-bold small text-uppercase">Mission</span> */}
                                            </a>

                                            <a class="nav-link mb-3 p-3 shadow" id="v-pills-vision-tab"
                                                data-bs-toggle="tab" data-bs-target="#v-pills-vision" role="tab"
                                                aria-controls="v-pills-vision" aria-selected="false" onClick={() => updateToggle(2)} className={toggle === 2 ? "nav-link mb-3 p-3 shadow active" : "nav-link mb-3 p-3 shadow"}>
                                                Vision
                                                {/* <!-- <i class="fas fa-calendar mr-2"></i> */}
                                                {/* <span class="font-weight-bold small text-uppercase">Vision</span>  */}
                                            </a>

                                        </div>
                                    </div>


                                    <div class="col-md-9">
                                        {/* <!-- Tabs content --> */}
                                        <div class="tab-content" id="v-pills-tabContent">
                                            <div class="tab-pane fade shadow rounded bg-white show active p-3"
                                                id="v-pills-mission" role="tabpanel"
                                                aria-labelledby="v-pills-mission-tab" tabindex="0" className={toggle === 1 ? "tab-pane fade shadow rounded bg-white p-3 show active" : "tab-pane fade shadow rounded bg-white p-3"}>
                                                <h4 class="font-italic mb-4">Our Mission</h4>
                                                <p class="font-italic text-muted mb-2">We at Harsafe Fintech , inventing
                                                    things that are made to lead by using some of the most advanced
                                                    technologies used in mobile app, ecommerce , wearable’s, IOT , ARVR,
                                                    cross platform, Cloud integration, crypto & web apps . We value our
                                                    customers and provide them with nothing but the best professional
                                                    experience in services thereby, helping their business to achieve
                                                    the pinnacle.

                                                </p>
                                            </div>

                                            <div class="tab-pane fade shadow rounded bg-white p-3" id="v-pills-vision"
                                                role="tabpanel" aria-labelledby="v-pills-vision-tab" tabindex="0" className={toggle === 2 ? "tab-pane fade shadow rounded bg-white p-3 show active" : "tab-pane fade shadow rounded bg-white p-3"}>
                                                <h4 class="font-italic mb-4">Our Vision</h4>
                                                <p class="font-italic text-muted mb-2">We strive to become one of the
                                                    most successful and preferred IT solutions partner for various
                                                    business needs like start-ups, SMBS and enterprises providing the
                                                    best mixture of innovation and technology leadership</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>



                                {/* <div class="row">
                                        <div class="col-sm-6">
                                            <div class="about-list-item-03">
                                                <h3 class="title">Our Mission</h3>
                                                <p>Accelerate innovation with world-class tech teams. We help businesses elevate their value.</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="about-list-item-03">
                                                <h3 class="title">Custom Code</h3>
                                                <p>Accelerate innovation with world-class tech teams. We help businesses elevate their value.</p>
                                            </div>
                                        </div>
                                    </div>  */}
                            </div>
                            {/* <!-- About List End --> */}
                        </div>
                        {/* <!-- About Content Wrap End --> */}
                    </div>
                </div>
            </div>
            {/* <!-- About Wrapper End --> */}
        </div>
    </div>
    // {/* <!-- About End --> */}
  )
}

export default WhoWe_About