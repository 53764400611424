import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import ServiceHero from './ServiceHero'
import ServiceM from './ServiceM'
import Footer from '../../Components/Footer/Footer';

const Service = () => {
  return (
    <div>
    <Navbar></Navbar>
       <ServiceHero></ServiceHero>
        <ServiceM></ServiceM>
        <Footer></Footer>
        </div>
  )
}

export default Service