import React from 'react'
import './ChooseUs.css'
import ServiceIcon9 from '../../../Img/ser-icon9.png'
import ServiceIcon10 from '../../../Img/ser-icon10.png'
import ServiceIcon12 from '../../../Img/ser-icon12.png'
import ServiceIcon21 from '../../../Img/ser-icon21.png'
import ServiceIcon22 from '../../../Img/ser-icon22.png'
import Counter1 from '../../../Img/counter-1.png'
import Counter2 from '../../../Img/counter-2.png'
import Counter3 from '../../../Img/counter-3.png'

const ChooseUs = () => {
  return (
    <div class="section harsafe-choose-us-section section-padding">
    <div class="container">
        {/* <!-- Choose Us Wrap Start --> */}
        <div class="choose-us-wrap">
            <div class="section-title text-center">
                <h3 class="sub-title">REASON TO CHOOSE US</h3>
                <h2 class="title">We provide truly prominent IT solutions.</h2>
            </div>
            <div class="choose-us-content-wrap">
                <div class="row">
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
                        {/* <!-- Choose Us Item Start --> */}
                        <div class="choose-us-item-02">
                            <div class="choose-us-img">
                            <img src={ServiceIcon9} alt=""/>
                            </div>
                            <div class="choose-us-content">
                                <h3 class="title"><a href="service.html">Infrustructure Technology</a></h3>
                                <p>Accelerate innovation with world-class tech teams We’ll match you to an entire.
                                </p>
                            </div>
                        </div>
                        {/* <!-- Choose Us Item End --> */}
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
                        {/* <!-- Choose Us Item Start --> */}
                        <div class="choose-us-item-02">
                            <div class="choose-us-img">
                            <img src={Counter3} alt=""/>
                            </div>
                            <div class="choose-us-content">
                                <h3 class="title"><a href="service.html">Highly professional team members</a></h3>
                                <p>Accelerate innovation with world-class tech teams We’ll match you to an entire.
                                </p>
                            </div>
                        </div>
                        {/* <!-- Choose Us Item End --> */}
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
                        {/* <!-- Choose Us Item Start --> */}
                        <div class="choose-us-item-02">
                            <div class="choose-us-img">
                                <img src={ServiceIcon10} alt=""/>
                            </div>
                            <div class="choose-us-content">
                                <h3 class="title"><a href="service.html">Security Management</a></h3>
                                <p>Accelerate innovation with world-class tech teams We’ll match you to an entire.
                                </p>
                            </div>
                        </div>
                        {/* <!-- Choose Us Item End --> */}
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
                        {/* <!-- Choose Us Item Start --> */}
                        <div class="choose-us-item-02">
                            <div class="choose-us-img">
                            <img src={ServiceIcon12} alt=""/>
                            </div>
                            <div class="choose-us-content">
                                <h3 class="title"><a href="service.html">Desktop Computing</a></h3>
                                <p>Accelerate innovation with world-class tech teams We’ll match you to an entire.
                                </p>
                            </div>
                        </div>
                        {/* <!-- Choose Us Item End --> */}
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
                        {/* <!-- Choose Us Item Start --> */}
                        <div class="choose-us-item-02">
                            <div class="choose-us-img">
                            <img src={Counter2} alt=""/>
                            </div>
                            <div class="choose-us-content">
                                <h3 class="title"><a href="service.html">Infrustructure Technology</a></h3>
                                <p>Accelerate innovation with world-class tech teams We’ll match you to an entire.
                                </p>
                            </div>
                        </div>
                        {/* <!-- Choose Us Item End --> */}
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
                        {/* <!-- Choose Us Item Start --> */}
                        <div class="choose-us-item-02">
                            <div class="choose-us-img">
                            <img src={Counter3} alt=""/>
                            </div>
                            <div class="choose-us-content">
                                <h3 class="title"><a href="service.html">Ideas for high return investment</a></h3>
                                <p>Accelerate innovation with world-class tech teams We’ll match you to an entire.
                                </p>
                            </div>
                        </div>
                        {/* <!-- Choose Us Item End --> */}
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
                        {/* <!-- Choose Us Item Start --> */}
                        <div class="choose-us-item-02">
                            <div class="choose-us-img">
                            <img src={ServiceIcon21} alt=""/>
                            </div>
                            <div class="choose-us-content">
                                <h3 class="title"><a href="service.html">Fully high IT Managment</a></h3>
                                <p>Accelerate innovation with world-class tech teams We’ll match you to an entire.
                                </p>
                            </div>
                        </div>
                        {/* <!-- Choose Us Item End --> */}
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 mb-3">
                        {/* <!-- Choose Us Item Start --> */}
                        <div class="choose-us-item-02">
                            <div class="choose-us-img">
                            <img src={ServiceIcon22} alt=""/>
                            </div>
                            <div class="choose-us-content">
                                <h3 class="title"><a href="service.html">Data secure & Managment</a></h3>
                                <p>Accelerate innovation with world-class tech teams We’ll match you to an entire.
                                </p>
                            </div>
                        </div>
                        {/* <!-- Choose Us Item End --> */}
                </div>

                </div>
            </div>
        </div>
        {/* <!-- Choose Us Wrap End --> */}
    </div>
</div>

  )
}


export default ChooseUs