import React from 'react'
import '../CTA_About/CTA_About.css'
import CTA_Icon2 from '../../../Img/cta-icon2.png'

const CTA_About = () => {
  return (
    <div class="section harsafe-cta-section-04 harsafe-cta-section-06 section-padding bg-imgss">
    <div class="container">
        <div class="cta-wrap">
            <div class="row align-items-center">
                <div class="col-lg-7 mb-4">
                    {/* <!-- Cta Left Start --> */}
                    <div class="cta-left">
                        <div class="section-title">
                            <h2 class="title">To make requests for further information, contact us </h2>
                        </div>
                    </div>
                    {/* <!-- Cta Left End --> */}
                </div>
                <div class="col-lg-5 mb-4">
                    {/* <!-- Cta Right Start --> */}
                    <div class="cta-info text-center">
                        <div class="cta-icon">
                            <img src={CTA_Icon2} alt=""/>
                        </div>
                        <div class="cta-text">
                            <p>Call Us For Any inquiry</p>
                            <h3 class="number"><a href="tel:+919351734009" target='_blank'>+91 9351 734 009</a></h3>
                        </div>
                    </div>
                    {/* <!-- Cta Right End --> */}
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default CTA_About