import React from 'react'
import '../Pricing/Pricing.css'

const Pricing = () => {
  return (
    <div class="section harsafe-pricing-section section-padding">
            <div class="container">
                {/* <!-- Pricing Wrap Start --> */}
                <div class="pricing-wrap">
                    <div class="section-title text-center">
                        <h3 class="sub-title">Pricing Plans</h3>
                        <h2 class="title">Affordable pricing for all</h2>
                    </div>
                    <div class="pricing-content-wrap">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                {/* <!-- Single Pricing Start --> */}
                                <div class="single-pricing">
                                    <div class="pricing-badge">
                                        <span class="title">Free</span>
                                    </div>
                                    <div class="pricing-price">
                                        <span class="currency">$</span>
                                        <h3 class="price">0<span>/Month</span></h3>
                                    </div>
                                    <div class="pricing-content">
                                        <ul class="pricing-list">
                                            <li>Community Support</li>
                                            <li>Dedicated Tech Experts</li>
                                            <li>Unlimited Storage</li>
                                            <li>Custom Domains</li>
                                        </ul>
                                        <div class="pricing-btn">
                                            <a class="btn" href="login-register.html">Try It Now</a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Single Pricing End --> */}
                            </div>
                            <div class="col-lg-4 col-md-6">
                                {/* <!-- Single Pricing Start --> */}
                                <div class="single-pricing active">
                                    <div class="pricing-badge">
                                        <span class="title">Starter</span>
                                    </div>
                                    <div class="pricing-price">
                                        <span class="currency">$</span>
                                        <h3 class="price">10<span>/Month</span></h3>
                                    </div>
                                    <div class="pricing-content">
                                        <ul class="pricing-list">
                                            <li>Community Support</li>
                                            <li>Dedicated Tech Experts</li>
                                            <li>Unlimited Storage</li>
                                            <li>Custom Domains</li>
                                        </ul>
                                        <div class="pricing-btn">
                                            <a class="btn" href="login-register.html">Try It Now</a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Single Pricing End --> */}
                            </div>
                            <div class="col-lg-4 col-md-6">
                                {/* <!-- Single Pricing Start --> */}
                                <div class="single-pricing">
                                    <div class="pricing-badge">
                                        <span class="title">Pro</span>
                                    </div>
                                    <div class="pricing-price">
                                        <span class="currency">$</span>
                                        <h3 class="price">30<span>/Month</span></h3>
                                    </div>
                                    <div class="pricing-content">
                                        <ul class="pricing-list">
                                            <li>Community Support</li>
                                            <li>Dedicated Tech Experts</li>
                                            <li>Unlimited Storage</li>
                                            <li>Custom Domains</li>
                                        </ul>
                                        <div class="pricing-btn">
                                            <a class="btn" href="login-register.html">Try It Now</a>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Single Pricing End --> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Pricing Wrap End --> */}
            </div>
        </div>
  )
}

export default Pricing