import React, { useEffect, useRef } from 'react';

const TextCounter = ({ value, duration = 1000 }) => {
  const counterRef = useRef(null);

  useEffect(() => {
    let start = 0;
    const end = parseInt(value, 10);
    let startTime = null;

    const animateCounter = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const current = Math.min(progress / duration, 1) * (end - start) + start;
      counterRef.current.innerText = Math.ceil(current);

      if (progress < duration) {
        requestAnimationFrame(animateCounter);
      }
    };

    requestAnimationFrame(animateCounter);
  }, [value, duration]);

  return (
      <span ref={counterRef}/>

  );
};

export default TextCounter;
