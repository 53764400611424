import React from "react";
import "../Features_SMS/Features_SMS.css";

import Super_Admin from '../../../Img/SchoolMS/Super_Admin.png'
import Admin from '../../../Img/SchoolMS/Admin.png'
import Teacher from '../../../Img/SchoolMS/Teacher.png'
import Accountant from '../../../Img/SchoolMS/Accountant.png'
import Receptionist from '../../../Img/SchoolMS/Receptionist.png'
import Student from '../../../Img/SchoolMS/Student.png'
import Parents from '../../../Img/SchoolMS/Parents.png'
import Librarian from '../../../Img/SchoolMS/Librarian.png'




const features = [
    {
      id: 1,
      title: "Super Admin",
      description: "Take Full Control With All Features",
      icon: Super_Admin,
      colorClass: "light-yellow",
    },
    {
      id: 2,
      title: "Admin",
      description: "Manage All Activities Almost All Features.",
      icon: Admin,
      colorClass: "navi-blue",
    },
    {
      id: 3,
      title: "Teacher",
      description: "Manage Students And Academics Activities",
      icon: Teacher,
      colorClass: "green",
    },
    {
      id: 4,
      title: "Accountant",
      description: "Trace Students Fees Expenses all at a Place",
      icon: Accountant,
      colorClass: "orange",
    },
    {
      id: 5,
      title: "Receptionist",
      description: "Manage all Front office relative Activities",
      icon: Receptionist,
      colorClass: "light-orange",
    },
    {
      id: 6,
      title: "Student",
      description: "Collaborate With School Teacher, Activities at All.",
      icon: Student,
      colorClass: "light-pink",
    },
    {
      id: 7,
      title: "Parents",
      description: "Panel for parents to monitor students performance",
      icon: Parents,
      colorClass: "pink",
    },
    {
      id: 8,
      title: "Librarian",
      description: "Manage library books, Generate library cards, Issue books to students and more..",
      icon: Librarian,
      colorClass: "black",
    },
  ];
  
  const FeatureBox = ({ icon, title, description, colorClass }) => (
    <div className="col-lg-3 col-md-6 col-sm-12">
      <div className={`feature-box-one ${colorClass}`}>
        <span className="icon">
          <img src={icon} alt={`School management ${title}`} />
        </span>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );



const Features_SMS = () => {
  return (
    <div className="section harsafe-service-section-07 section-padding">
      <div className="container">
        {/* <!-- Service Wrap Start --> */}
        <div className="service-wrap">
          <div className="section-title text-center">
            <h3 className="sub-title color-3">Features</h3>
            <h2 className="title">We Have Exclusive Features</h2>
          </div>
          <div className="service-content-wrap">
          <div className="row overflow gy-3">
    {features.map((feature) => (
      <FeatureBox key={feature.id} {...feature} />
    ))}
  </div>
          </div>
        </div>
        {/* <!-- Service Wrap End --> */}
      </div>
    </div>
  );
};

export default Features_SMS;
