import React from 'react'
import '../Counter_Exp/Counter_Exp.css'

const Counter_Exp = () => {
  return (
    <div class="section harsafe-service-section-04 harsafe-service-section-07 section-padding">
            <div class="container">
                {/* <!-- Service Wrap Start --> */}
                <div class="service-wrap">
                    <div class="section-title text-center">
                        <h3 class="sub-title color-3">School Management Solutions</h3>
                        <h2 class="title">Highly Tailored School Management &amp; Support Services. </h2>
                    </div>
                    <div class="service-content-wrap">
                        <div class="row">
                            <div class="col-lg-6">
                                {/* <!-- Experience Wrap Start --> */}
                                <div class="experience-wrap">
                                    <img class="shape-1" src="assets/images/shape/experince-shape2.png" alt=""/>
                                    <div class="experience">
                                        <h3 class="number">25+</h3>
                                        <span>Years Experience Working</span>
                                    </div>
                                </div>
                                {/* <!-- Experience Wrap End --> */}
                            </div>
                            <div class="col-lg-6">
                                {/* <!-- Service Content Start --> */}
                                <div class="service-content">
                                    <p class="text">Transform education with cutting-edge management solutions. We’ll provide you with a comprehensive platform and dedicated support to streamline all your school administration needs.</p>
                                    <div class="service-list">
                                        <ul>
                                            <li>
                                                <span class="service-icon"><i class="fas fa-check"></i></span>
                                                <span class="service-text">Simplify tasks like attendance, grading, and scheduling with our intuitive interface.</span>
                                            </li>
                                            <li>
                                                <span class="service-icon"><i class="fas fa-check"></i></span>
                                                <span class="service-text"> Foster seamless communication between teachers, students, and parents through integrated messaging and notifications</span>
                                            </li>
                                            <li>
                                                <span class="service-icon"><i class="fas fa-check"></i></span>
                                                <span class="service-text">Utilize powerful analytics to make informed decisions and improve educational outcomes</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <!-- Service Content En --> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Service Wrap End --> */}
            </div>
        </div>
  )
}

export default Counter_Exp