import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import HeroAbout from './HeroAbout/HeroAbout'
import WhoWe_About from './WhoWe_About/WhoWe_About'
import ChooseUs from './ChooseUs/ChooseUs'
import CTA_About from './CTA_About/CTA_About'
import Brand_Logo from '../../Components/Brand_Logo/Brand_Logo';
import Footer from '../../Components/Footer/Footer';

const AboutUs = () => {
  return (
    <div>
    <Navbar></Navbar>
        <HeroAbout></HeroAbout>
        <WhoWe_About></WhoWe_About>
        <ChooseUs></ChooseUs>
        <CTA_About></CTA_About>
        <Brand_Logo></Brand_Logo>
        <Footer></Footer>
        </div>
  )
}

export default AboutUs