import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import HeroBlog from './HeroBlog'
import BlogMain from './BlogMain'
import Brand_Logo from '../../Components/Brand_Logo/Brand_Logo';
import Footer from '../../Components/Footer/Footer';

const Blog = () => {
  return (
    <div>
    <Navbar></Navbar>
        <HeroBlog></HeroBlog>
        <BlogMain></BlogMain>
        <Brand_Logo></Brand_Logo>
        <Footer></Footer>
        </div>
  )
}

export default Blog