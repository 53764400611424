import React from 'react'
import '../WhoWeAre/WhoWeAre.css'
import AboutBig from '../../Img/about-big.png';
import AboutSmall from '../../Img/about-sm.png';



const WhoWeAre = () => {
  return (
    <div class="section hersafe-about-section-03 section-padding-02">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    {/* <!-- About Content Start --> */}
                    <div class="about-content-03">
                        <div class="section-title">
                            <h3 class="sub-title color-2">Who we are</h3>
                            <h2 class="title">We run all kinds of software services that vow your success</h2>
                        </div>
                        <p class="text">Accelerate innovation with world-class tech teams We’ll match you to an entire
                            remote team of incredible freelance talent for all your software development needs.</p>
                        <div class="about-quote">
                            <blockquote class="blockquote">
                                <p>Accelerate innovation with world-class tech teams We’ll match you to an entire
                                    remote.</p>
                            </blockquote>
                        </div>
                        {/* <!-- About List Start --> */}
                        <div class="about-list-02">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="about-list-item-02">
                                        <h3 class="title"><i class="fas fa-arrow-circle-right"></i> Expert Team</h3>
                                        <p>Accelerate innovation with world-class tech teams</p>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="about-list-item-02">
                                        <h3 class="title"><i class="fas fa-arrow-circle-right"></i>Custom Code</h3>
                                        <p>Accelerate innovation with world-class tech teams</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- About List End --> */}
                    </div>
                    {/* <!-- About Content End --> */}
                </div>
                <div class="col-lg-6">
                    {/* <!-- About Image Warp Start --> */}
                    <div class="about-img-warp-3">
                        <div class="shape-1"></div>
                        <div class="about-img about-img-big">
                            <img src={AboutBig} alt=""/>
                        </div>
                        <div class="about-img about-img-sm">
                            <img src={AboutSmall} alt=""/>
                        </div>
                    </div>
                    {/* <!-- About Image Warp End --> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhoWeAre