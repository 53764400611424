import React from "react";
import "./OurTeamMain.css";
import Link_Arrow from '../../Img/team/link-arrow.png'
import Linkedin from '../../Img/team/linkedin.svg'
import Dharmendra from '../../Img/team/dharmendra.png'
import Boss from '../../Img/team/kamlesh.png'
import Max from '../../Img/team/mahaveer.png'
import Yogesh from "../../Img/team/yogesh.png";
import Chintu from "../../Img/team/chintu.png";
import Ravi from "../../Img/team/ravi.png";
import Mayank from "../../Img/team/mayank.png";
import Priya from "../../Img/team/priya.png";
import Sangeeta from "../../Img/team/sangeeta.png";
import Parul from "../../Img/team/parul.png";






const teamMembers = [
  {
      name: "Yogesh Ashiwal",
      role: "Sr. App Developer",
      imgSrc: Yogesh,
      description: "A leader in app development, pioneering innovation and delivering seamless experiences under expert guidance."
  },
  {
      name: "Deepak Kumawat",
      role: "Sr. Web Developer",
      imgSrc: Chintu,
      description: "Our Senior Backend Developer ensures robust performance and scalability, driving excellence in backend solutions with innovative strategies."
  },
  {
      name: "Ravikant Saini",
      role: "Sr. Area Sales Manager",
      imgSrc: Ravi,
      description: "Ravi Seni excels as an Area Sales Manager, driving revenue growth through strategic planning and exceptional leadership skills."
  },
  {
      name: "Mayank Raj Singh",
      role: "Area Sales Manager",
      imgSrc: Mayank,
      description: "Mayank Raj Singh excels as an Area Sales Manager, leading sales strategies and driving growth with exceptional leadership."
  },
  {
      name: "Priya",
      role: "Sr. Customer Executive",
      imgSrc: Priya,
      description: "Our Senior Customer Executive, delivers exceptional service, ensuring client satisfaction through dedication, expertise, and personalized attention."
  },
  {
      name: "Sangeeta",
      role: "Customer Executive",
      imgSrc: Sangeeta,
      description: "Our dedicated Customer Executive, ensures exceptional service, addressing client needs with professionalism, empathy, and efficiency."
  },
  {
      name: "Parul",
      role: "Customer Executive",
      imgSrc: Parul,
      description: "Our attentive Customer Executive, delivers personalized service, addressing client queries with professionalism, care, and dedication."
  },
  
];



const OurTeamMain = () => {
  return (
    // <!-- Team Start -->
    <div className="main_team">
      <section class="section section-padding aboutUs_gleam">
        <div class="container">
          <div class="aboutUs_gleam_content">
            <div class="left_part">
              <div class="left_part_images">
                <img src={Dharmendra} alt="" />
              </div>
              <div class="left_part_content">
                <h2>Dharmendra Kumar Karigar</h2>
                <p>Founder &amp; CEO</p>
                <p class="aboutUs_linkedIn">
                  {" "}
                  <span>
                    <img
                      src={Link_Arrow} alt=""
                    />
                  </span>{" "}
                  <a className="link-link"
                    href="https://www.linkedin.com/"
                    target="_blank"
                  >
                    <img className="libkdi"
                      src={Linkedin} alt=""
                    />
                  </a>
                </p>
              </div>
            </div>
            <div class="right_part">
              <h2>Pioneering Innovation at Harsafe Technology Finance Service</h2>
              <p>Meet Dharmendar, the visionary founder and CEO spearheading innovation at Harsafe Technology Finance Service. With an unwavering commitment to pioneering solutions and a relentless pursuit of excellence, Dharmendar founded our company with a singular goal: to redefine the landscape of technology and finance services</p>
              <p>As both founder and CEO, Dharmendar brings to the table a unique blend of strategic foresight, industry expertise, and entrepreneurial spirit. His leadership is anchored in a deep understanding of market dynamics and a forward-thinking approach that consistently pushes boundaries.</p>
              <p>Under Dharmendar's guidance, Harsafe fosters a culture of creativity, collaboration, and integrity. His vision inspires our team to consistently deliver innovative solutions that exceed expectations and drive tangible results for our clients.</p>
              <p>Join us on our journey, guided by Dharmendar's vision and fueled by our collective passion for revolutionizing the intersection of technology and finance. Together, we will continue to lead, innovate, and shape the future of our industry.</p>
            </div>
          </div>
        </div>
      </section>
      <section class="section-padding section aboutUs_gleam right-img">
        <div class="container">
          <div class="aboutUs_gleam_content">
            <div class="left_part">
              <div class="left_part_images">
                <img src={Max}
                  alt=""
                />
              </div>
              <div class="left_part_content">
                <h2>Mahaveer Prasad</h2>
                <p>Founder</p>
                <p class="aboutUs_linkedIn">
                  {" "}
                  <span>
                    <img
                      src={Link_Arrow} alt=""
                    />
                  </span>{" "}
                  <a className="link-link"
                    href="https://www.linkedin.com/in/mahaveer-prasad-3a1207248/"
                    target="_blank"
                  >
                    <img className="libkdi"
                      src={Linkedin} alt=""
                    />
                  </a>
                </p>
              </div>
            </div>
            <div class="right_part">
              <h2>Charting the Course at Harsafe Technology Finance Service</h2>
              <p>Meet Mahaveer Prasad, the ingenious founder shaping the trajectory of Harsafe Technology Finance Service. With a visionary outlook and an unwavering commitment to excellence, Mahaveer established our company with a clear mission: to drive innovation and transformation in the technology and finance sectors.</p>
              <p>As the founder, Mahaveer Prasad brings to the helm a wealth of experience, coupled with a passion for groundbreaking solutions. His leadership is characterized by a profound understanding of industry nuances and a forward-thinking approach that continually pushes boundaries.</p>
              <p>Under Mahaveer's guidance, Harsafe cultivates a culture of innovation, collaboration, and integrity. His vision inspires our team to relentlessly pursue excellence and deliver cutting-edge solutions that exceed expectations.</p>
              <p>Join us on this journey, guided by Mahaveer Prasad's vision and fueled by our collective determination to redefine the future of technology and finance. Together, we will continue to innovate, lead, and make a lasting impact in our industry.</p>
            </div>
          </div>
        </div>
      </section>
      <section class="section-padding section aboutUs_gleam">
        <div class="container">
          <div class="aboutUs_gleam_content">
            <div class="left_part">
              <div class="left_part_images">
                <img src={Boss}
                  alt=""
                />
              </div>
              <div class="left_part_content">
                <h2>Kamlesh Kumawat</h2>
                <p>Co-Founder &amp; CTO</p>
                <p class="aboutUs_linkedIn">
                  {" "}
                  <span>
                    <img
                      src={Link_Arrow} alt=""
                    />
                  </span>{" "}
                  <a className="link-link"
                    href="https://www.linkedin.com/in/sitkamal/"
                    target="_blank"
                  >
                    <img className="libkdi"
                      src={Linkedin} alt=""
                    />
                  </a>
                </p>
              </div>
            </div>
            <div class="right_part">
              <h2>Driving Technological Excellence at Harsafe Technology Finance Service</h2>
              <p>Meet Kamlesh Kumawat, the dynamic co-founder and CTO spearheading technological advancement at Harsafe Technology Finance Service. With a profound expertise in technology and a relentless pursuit of innovation, Kamlesh co-founded our company with a clear mission: to harness the power of technology to transform the finance industry.</p>
              <p>As both co-founder and CTO, Kamlesh brings to the table a unique blend of strategic vision and technical prowess. His leadership is grounded in a deep understanding of cutting-edge technologies and a commitment to delivering scalable and efficient solutions.</p>
              <p>Under Kamlesh's guidance, Harsafe fosters a culture of innovation, collaboration, and technical excellence. His vision inspires our team to push the boundaries of what's possible, constantly striving for technological innovation that delivers real value to our clients.</p>
              <p>Join us on this journey, led by Kamlesh Kumawat's expertise and fueled by our collective passion for leveraging technology to drive positive change in the finance sector. Together, we will continue to innovate, lead, and shape the future of finance through technology.</p>
            </div>
          </div>
        </div>
      </section>
      <div className="section harsafe-team-section harsafe-team-section-03 section-padding">
            <div className="container">
                <div className="team-wrap">
                    <div className="row gy-5">
                        {teamMembers.map((member, index) => (
                            <div className="col-lg-3 col-sm-6" key={index}>
                                <div className="single-team">
                                    <span className="team-img">
                                        <img src={member.imgSrc} alt={member.name} />
                                    </span>
                                    <div className="aboutUs_team_block">
                                        <h2>{member.name}</h2>
                                        <h3>{member.role}</h3>
                                        <p>{member.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
       {/* <!-- Team End --> */}
    </div>
  );
};

export default OurTeamMain;
